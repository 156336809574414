import React, { useEffect, useState } from "react";
import GoogleBtn from "../../component/GoogleBtn.js";
import stateLogo from "../../assets/SEEDhorizontal.png";
import essayLogo from "../../assets/redacao.png";
import loginLogo from "../../assets/logoLogin2.png";
import warningImg from "../../assets/warning.png";
import { Container } from "./styles";
import dotenv from "dotenv";
import Modal from "react-modal";
import { Button } from "@material-ui/core";

interface LoginProps {}

Modal.setAppElement("#root"); // Configure o app do react-modal

export function Login({}: LoginProps) {
  const [isStaging, setIsStaging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dotenv.config({ path: ".env.staging" });
    setIsStaging(
      process.env.REACT_APP_API_URL === "https://redacao-api-homolog.pr.gov.br/"
    );
  }, []);

  useEffect(() => {
    if (isStaging) {
      setIsModalOpen(true);
    }
  }, [isStaging]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const accessRedacao = () => {
    window.location.replace("https://redacao.pr.gov.br/login");
  };
  return (
    <div>
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transform: "none",
            backgroundColor: "#f2f2f2",
          },
        }}
      >
        <div
          id="wrapper"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center", // Centralize na vertical
            height: "90%", // Preencha a altura
          }}
        >
          <img id="warningImg" alt="Atenção" src={warningImg} width="150" />
          <h1 style={{color: "var(--blue-light)", fontWeight:"bold", textTransform:"uppercase"}}>ATENÇÃO.</h1>
          <br></br>
          <h2 style={{color: "var(--blue-light)", fontWeight:"bold", textTransform:"uppercase"}}>
            Você está acessando o ambiente de homologação do Redação Paraná.
          </h2>
          <h2 style={{color: "var(--blue-light)", fontWeight:"bold", textTransform:"uppercase"}}>
            Entrou aqui por engano?{" "}
            <a href="https://redacao.pr.gov.br/login" style={{textDecoration:"none", color:"#12AA33", cursor:"pointer"}}>
              Acesse a página oficial do Redação Paraná
            </a>
          </h2>
          <br></br>
          <div
            id="btnContainer"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={closeModal}
              style={{
                backgroundColor: "#f2f2f2",
                color: "var(--blue-light)",
                border: "2px solid var(--blue-light)",
                padding: "1%",
                flex: 1,
                marginRight: "8px",
              }}
            >
              <h3>Prosseguir para Homologação</h3>
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={accessRedacao}
              style={{
                backgroundColor: "var(--blue-light)",
                color: "var(--white)",
                border: "2px solid var(--blue-light)",
                padding: "1%",
                flex: 1,
              }}
            >
              <h3>Acessar o Redação Paraná</h3>
            </Button>
          </div>
        </div>
      </Modal>
      {!isModalOpen && (
        <Container>
          <div style={{ zIndex: 10 }}>
            <a href="https://redacao.pr.gov.br/" target="_blank">
              <img
                id="redacaoLogo"
                alt="Redação Logo"
                src={essayLogo}
                width="200"
              />
            </a>

            <p id="txtLogin">FAÇA O LOGIN COM SEU EMAIL @ESCOLA</p>

            <GoogleBtn />

            <a
              href="https://www.educacao.pr.gov.br/"
              target="_blank"
              id="paranaLogo"
            >
              <img
                alt="SECRETARIA DE ESTADO DA EDUCAÇÃO"
                src={stateLogo}
                style={{ marginTop: 60, marginBottom: 15 }}
                width="200"
              />
            </a>
          </div>
        </Container>
      )}
    </div>
  );
}

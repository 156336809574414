import React from "react";
import { Topbar } from "../../../component/Topbar";

import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

function Theme() {
	const classes = useStyles();

	return (
		<>
			<div>
				<Topbar profile="11" menu="home" />
			</div>
			<div className={classes.container}>
				<div className={classes.centered}>
					<Autocomplete
						id="combo-box-demo"
						options={redacaoPara}
						className={classes.buttons}
						getOptionLabel={(option) => option.title}
						style={{ width: 400 }}
						renderInput={(params) => (
							<TextField {...params} label="Redação para" variant="outlined" />
						)}
					/>

					<Autocomplete
						id="combo-box-demo"
						options={redacaoPara}
						className={classes.buttons}
						getOptionLabel={(option) => option.title}
						style={{ width: 400 }}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Gênero textual"
								variant="outlined"
							/>
						)}
					/>

					<Autocomplete
						id="combo-box-demo"
						options={temas}
						getOptionLabel={(option) => option.title}
						className={classes.buttons}
						style={{ width: 400 }}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Tema da redação"
								variant="outlined"
							/>
						)}
					/>
					<Typography variant="body1" component="body1">
						Tema da redação: Brasil pós pandemia
					</Typography>

					<Typography variant="body1" component="body1">
						Data proposta: 09/09/2020
					</Typography>
				</div>

				<div className={classes.centered}>
					<Typography variant="body2" component="body2">
						Por meio desses itens, o técnico pedagógico da Seed selecionará
						dados que irão compor a proposta de redação, a qual será postada no
						Google Classroom como atividade para os alunos. No lado esquerdo da
						página, o técnico indicará o propósito da redação, o gênero textual,
						o tema e as datas de início e término que o aluno terá para elaborar
						e entregar sua redação. Do lado direito da página, estarão os itens
						de seleção de série, espaço para o técnico redigir a proposta de
						redação e critérios que deverão ser respeitados pelos alunos durante
						a elaboração da redação. Além de um botão de “Salvar proposta”
						(figura 33).
					</Typography>

					<TextField
						label="Comentário"
						fullWidth="true"
						className={classes.container}
						variant="outlined"
					></TextField>

					<div className={classes.container}>
						<Button
							variant="contained"
							color="primary"
							className={classes.buttons}
						>
							Aprovar
						</Button>

						<Button
							variant="contained"
							color="secondary"
							className={classes.buttons}
						>
							Rejeitar
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}

export default Theme;

const redacaoPara = [
	{ title: "Vestibular", id: 1 },
	{ title: "Enem", id: 2 },
	{ title: "Atividade Escolar", id: 3 },
];

const temas = [
	{ title: "Volta as aulas ", id: 1 },
	{ title: "Brasil pós pandemia", id: 2 },
	{ title: "Covid", id: 3 },
];

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		maxWidth: "100%",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "row",
	},
	centered: {
		display: "flex",
		flexDirection: "column",

		maxWidth: "50%",
		marginRight: "10%",
		marginLeft: "10%",
		marginTop: "1%",
		justifyContent: "center",
		alignItems: "center",
	},
	buttons: {
		display: "flex",
		margin: "1%",
		justifyContent: "center",
		alignItems: "center",
	},
}));

import React, { useEffect } from 'react'
import { apiSecure } from 'domain/api'

export function GetPending(props) {
  useEffect(() => {
    apiSecure
      .get('/Essay/teacher/l/pending')
      .then(function (response) {
        props.callback(response.data)
      })
      .catch(function (error) {})
  }, [])
  return <></>
}

export function GetRecent(props) {
  useEffect(() => {
    apiSecure
      .get('/Essay/teacher/l/recent/review')
      .then(function (response) {
        props.callback(response.data)
      })
      .catch(function (error) {})
  }, [])
  return <></>
}

export function GetHistory(props) {
  useEffect(() => {
    apiSecure
      .get('/Essay/teacher/l/classrooms/' + 1)
      .then(function (response) {
        props.callback(response.data)
        if (response.data === []) {
          props.historyList(true)
        }
      })
      .catch(function (error) {
        console.error(error)
      })
  }, [])
  return <></>
}

export function GetDetails(props) {
  useEffect(() => {
    apiSecure
      .get('/Essay/teacher/token/' + props.token)
      .then(function (response) {
        props.callback(response.data)
      })
      .catch(function (error) {})
  }, [])
  return <></>
}

import React, { useEffect } from "react";
import { apiSecure } from "domain/api";

export function GetCorrections(props) {
  useEffect(() => {
    apiSecure
      .get("/Essay/teacher/student/" + props.token + "/history")
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);
  return <></>;
}


export function GetCorrectionsHistoryStudent(props) {
  useEffect(() => {
    apiSecure
      .get("/Essay/student/" + props.id + "/history")
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);
  return <></>;
}
import styled from 'styled-components';

export const Container = styled.div`

    div {
        position: sticky;
        top: 0;
        width: 100%;
        max-width: 60rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
        padding: 0 24px;
        margin: 40px auto 0;
        overflow: hidden;

    }

    .pSimple {
        font-weight: bold;
        color: var(--blue-light);
        text-transform: uppercase;
    }

    .pSimple2 {
        text-align: justify;
        line-height: 1.5;
        text-indent: 6%
    }

    .pSimple3 {
        font-size: 0.8rem;
        line-height: 1.5;
        text-transform: uppercase;
    }

    .pSimple4 {
        text-align: left;
        list-style-type: circle;
        text-align: justify;
    }

`;


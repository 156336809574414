import React from "react";
import "fontsource-roboto";
import { useHistory } from "react-router-dom";
import {Container, Content} from './styles'

interface History{
	classroomId: string;
	type: string;
	essayId: string;
	description: string;
	creationDate: Date;
	token: string;
	studentId: number;
	genre: string;
	school: string;
	classroom: string;
}


export default function HistoryLine(props : History) {
	
	let history = useHistory();

	return (
		<>
			<Container onClick={() => history.push({pathname: "/class/"+props.classroomId, state:{classroomId: props.classroomId}})}>

						<h3>{props.type}</h3>
					<p>{props.description}</p>

					<p>
						{props.creationDate}
					</p>
			</Container>
		
		</>
	);
}


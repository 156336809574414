import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function CorrectionHistory(props) {
  const classes = useStyles();
  
  // Inicialização de estados com base nos props
  const [id, setId] = useState(() => {
    const matchedOption = props.e.options.find(
      (o) => o.score === props.notaCorrespondente
    );
    return matchedOption ? matchedOption.id : 0;
  });

  const [score, setScore] = useState(() => {
    const matchedOption = props.e.options.find(
      (o) => o.score === props.notaCorrespondente
    );
    return matchedOption ? matchedOption.score : 0;
  });

  const [selected, setSelected] = useState(() => {
    return props.e.options.some(
      (o) => o.score === props.notaCorrespondente
    );
  });

  // Verificar automaticamente se a nota corresponde à nota passada e atualizar o estado
  useEffect(() => {
    if (props.e?.options?.length) {
      const matchedOption = props.e.options.find(
        (o) => o.score === props.notaCorrespondente
      );
      if (matchedOption) {
        console.log("match", matchedOption);
        setId(matchedOption.id);
        setScore(matchedOption.score);
        setSelected(true);
      }
    }
  }, [props.notaCorrespondente, JSON.stringify(props.e.options)]);

  function handleChange(id, score) {
    if (selected === true && id === id) {
      setSelected(false);
      setId(0);
      setScore(0);
      props.handleChange(props.e.id, null, null);
      console.log("Deselecionado");
    } else {
      setId(id);
      setScore(score);
      setSelected(true);
      props.handleChange(props.e.id, id, score);
      console.log("Selecionado:", { id, score });
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {props.e.options.map((o) => (
        <div
          key={o.descriptorId + "_" + o.id}
          id={o.id}
          className={classes.container}
          style={{
            borderColor:
              selected === true && id === o.id ? "#9ef01a" : "#e8e8e8",
            backgroundColor:
              selected === true && id === o.id ? "#9ef01a4d" : "#e8e8e8",
          }}
          onClick={() => handleChange(o.id, o.score)}
          hidden={selected === true && id !== o.id}
        >
          <p style={{ color: "black", textAlign: "justify", margin: "2px 0px" }}>
            <b>Nota: {o.score}</b> — {o.description}
          </p>
        </div>
      ))}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    border: "1px solid lightgray",
    borderRadius: 10,
    margin: 5,
    cursor: "pointer",
    padding: "0 10px 0 10px",
    "&:hover": {
      filter: "brightness(0.9)",
    },
  },
}));

import styled from 'styled-components'

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem;
    padding: 1rem;
    background: var(--white);
    border-radius: 0.25rem;
    h3{
        display: block;
        width: 90%;
    }

`

export const ClassItem = styled.div`
        display: block;
        margin: 15px;
        padding: 20px;
        background: var(--white);
        border-radius: 0.25rem;
        border-width: 0.15rem;
        border-style: solid;
        border-color: var(--background);
        width: 100%;
        cursor: pointer;
        border-bottom-color: var(--green);

        button{
            height: 2rem;
            margin: 0.2rem;
            border: 0;
            padding: 0.1rem;
            color: var(--green);
            display: inline-block;
            text-align: center;
            min-width: 15rem;

            i{
                font-size:1.7rem; 
                vertical-align: middle
            }

        }

        @media(max-width: 900px){
            width: 100%;
        }

        &:hover{
            filter: brightness(0.95);
            }
`


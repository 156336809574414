import React, { useState } from "react";
import LineCard from "component/LineCard";
import { GetCorrections } from "domain/correction/service";
import moment from "moment";

export default function CorrectionHistory(props) {
	const [correctionHistory, setCorrectionHistory] = useState([]);

	function GetScore(e) {
		//console.log(e);
		let score = e.score*2.5;
		let maxScore = e.status == 3 ? "200" : "1000";
		let reviewScore = e.reviewScore ? e.reviewScore : 0;
		return `${Number(score + reviewScore).toFixed(0)}/${maxScore}`;
	}

	return (
		<>
			<GetCorrections
				token={props.token}
				callback={(e) => {
					setCorrectionHistory(e);
				}}
			/>
			{correctionHistory &&
				correctionHistory.map((e) => (
					<>
						<LineCard
							key={e}
							topLeft={
								e.statusDescription +
								` - Realizada ${moment(e.reviewedAt ?? e.deliveredAt)
									.utc(true)
									.utcOffset("00:00")
									.locale("pt-br")
									.startOf("second")
									.fromNow()}`
							}
							// status={e.status}
							// statusDescription={e.statusDescription}
							essayId={e.essayId}
							teacherName={e.reviewerName}
							description={e.description}
							title={``}
							accordionText={
								e.reviewText !== undefined &&
								`Professor: ${e.reviewerName}\nDevolutiva do Professor: ${e.reviewText}`
							}
							topRightText={GetScore(e)}
							data={moment(e.deliveredAt)
								.utc(true)
								.utcOffset("-03:00")
								.format("DD/MM/YYYY HH:mm")}
						/>
					</>
				))}
		</>
	);
}

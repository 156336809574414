import React, { useEffect } from "react";
import { apiSecure } from "../../api";

export function GetDetails(props) {
  useEffect(() => {
    apiSecure
      .get("/Theme/" + props.id)
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {});
  }, []);
  return <></>;
}

export function GetMotivatingText(props) {
  useEffect(() => {
    apiSecure
      .get("/Theme/motivatingtexts?themeId=" + props.id)
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {});
  }, []);
  return <></>;
}

export function GetGenre(props) {
  useEffect(() => {
    apiSecure
      .get("/Theme/l/Genre")
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {});
  }, []);
  return <></>;
}

export function GetType(props) {
  useEffect(() => {
    apiSecure
      .get("/Theme/l/type")
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {});
  }, []);
  return <></>;
}

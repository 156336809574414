import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

class DistributedColumnsChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: this.mapPropsToSeriesData(props),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              //console.log(chart, w, e)
            },
          },
        },
        colors: [
          "#0F4665",
          "#2584BA",
          "#0F4665",
          "#2584BA",
          "#0F4665",
          "#2584BA",
        ],
        plotOptions: {
          bar: {
            columnWidth: "80%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            ["PENDENTES"],
            ["RASCUNHO"],
            ["CORREÇÃO", "ONLINE"],
            ["AGUARDANDO", "CORREÇÃO"],
            ["AGUARDANDO", "REESCRITA"],
            ["CONCLUÍDAS"],
            ["TOTAL DE", "REDAÇÕES"],
          ],
          labels: {
            style: {
              colors: [
                "#0F4665",
                "#2584BA",
                "#0F4665",
                "#2584BA",
                "#0F4665",
                "#2584BA",
              ],
              fontSize: "14px",
            },
          },
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        series: [{
          data: this.mapPropsToSeriesData(this.props),
        }],
      });
    }
  }

  mapPropsToSeriesData(props) {
    return [
      { x: "PENDENTES", y: props.pendentes },
      { x: "RASCUNHO", y: props.emRascunho },
      { x: "CORREÇÃO ONLINE", y: props.emCorrecaoOnline },
      { x: "AGUARDANDO CORREÇÃO", y: props.enviadasCorrecao },
      { x: "AGUARDANDO REESCRITA", y: props.devolvidasReescrita },
      { x: "CONCLUÍDAS", y: props.concluidas },
      { x: "TOTAL", y: props.qtdRedacoes },
    ];
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={400}
          width={380}
        />
      </div>
    );
  }
}

DistributedColumnsChart.propTypes = {
  notaPendentes: PropTypes.number.isRequired,
  notaCorrecaoOnline: PropTypes.number.isRequired,
  notaRascunho: PropTypes.number.isRequired,
  notaAguardandoReescrita: PropTypes.number.isRequired,
  notaAguardandoCorrecao: PropTypes.number.isRequired,
  notaConcluidas: PropTypes.number.isRequired,
  notaTotal: PropTypes.number.isRequired,
};

export default DistributedColumnsChart;

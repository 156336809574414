import React from "react";
import { Topbar } from "../../../component/Topbar";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";

const AdministratorHome = () => {
	const classes = useStyles();

	return (
		<>
			<head>
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/icon?family=Material+Icons"
				></link>
			</head>
			<div>
				<Topbar profile="11" menu="home" />
			</div>
			<div className={classes.centered}>
				<MaterialTable
					actions={[
						{
							icon: "launch",
							tooltip: "Abrir",
							onClick: (event, rowData) => {
								return (window.top.location.href = "/administrator/theme/id");
							},
						},
					]}
					title="Propostas de temas"
					columns={[
						{ title: "Professor", field: "professor", sorting: false },
						{ title: "RedacaoPara", field: "redacaopara" },
						{ title: "Tema", field: "tema" },
						{ title: "DataProposta", field: "dataProposta" },
					]}
					data={[
						{
							tema: "Retorno as aulas",
							dataProposta: "09/09/2020",
							professor: "Maria",
							redacaopara: "Vestibular",
						},
						{
							tema: "Covid-19",
							dataProposta: "09/09/2020",
							professor: "José",
							redacaopara: "Enem",
						},
						{
							tema: "Brasil pós pandemia",
							dataProposta: "09/09/2020",
							professor: "João",
							redacaopara: "Atividade Escolar",
						},
					]}
					options={{
						sorting: true,
						search: false,
					}}
					localization={{
						body: {
							emptyDataSourceMessage: "Nenhum registro para exibir",
						},
						header: {
							actions: "Visualizar",
						},
						toolbar: {
							searchTooltip: "Pesquisar",
						},
						pagination: {
							labelRowsSelect: "linhas",
							labelDisplayedRows: "{count} de {from}-{to}",
							firstTooltip: "Primeira página",
							previousTooltip: "Página anterior",
							nextTooltip: "Próxima página",
							lastTooltip: "Última página",
						},
					}}
				/>
			</div>
		</>
	);
};

export default AdministratorHome;

const useStyles = makeStyles((theme) => ({
	centered: {
		marginRight: "10%",
		marginLeft: "10%",
		marginTop: "1%",
		justifyContent: "center",
		alignItems: "center",
	},
	buttons: {
		display: "flex",
		margin: "1%",
		justifyContent: "center",
		alignItems: "center",
	},
}));

import styled from 'styled-components'

export const Container = styled.div`
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    #txtLogin{
        margin-bottom: 10%;
        text-transform: uppercase;
        font-weight: bold;
    }

    div{
        vw:30%;
        vh: 30%;
        background: var(--white);
        margin: auto;
        max-width: 400px;
        border: none;
        border-radius: 10px;
        padding:20px;
        z-index: 100;
        float: center;
        text-align: center;
        img{
            display: block;
            margin: 0 auto;
        }
        @media(max-width: 600px){
            margin-top:25%;
        }
    }

    @media(max-width: 600px){
        background: var(--white);
        height:100%;
        width:100%;

        #redacaoLogo{
            margin-bottom: 10%;
        }

        #paranaLogo{
            position:absolute;
            bottom:5%;
            left: 50%;
            transform: translateX(-50%);
        }

        #txtLogin{
            margin-top:15%;
            font-size:14pt;
            text-transform: uppercase;
        }
    }

    @media(min-width: 601px){
        #redacaoLogo{
            margin-top: 3%;
            margin-bottom: 80px;
        }
    }
`


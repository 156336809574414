export interface EssayCreate {
  themeId: never[];
  startDate: string;
  endDate: string;
  details: string;
  classroomIds: never[];
  minAmountWords: number;
  maxAmountWords: number;
}

export const initialEssayCreate: EssayCreate = {
  themeId: [],
  startDate: "",
  endDate: "",
  details: "",
  classroomIds: [],
  minAmountWords: 0,
  maxAmountWords: 0,
};

import * as React from "react";
import AxiosRequestBuilder from "../services/axiosRequestBuilder";
import { EssayCreate } from "../_Types/essayCreate";
import { EssaySave } from "../_Types/essaySave";
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
type Props = /*unresolved*/ any;

export const CreateEssay = async (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setCreatedEssay: (value: any) => void,
  setOpenDialog: (value: React.SetStateAction<boolean>) => void,
  setSend: (value: React.SetStateAction<boolean>) => void,
  essay: EssayCreate,
  history: any,
  setSuccess_: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen_: React.Dispatch<React.SetStateAction<boolean>>,
  open_: boolean
) => {
  try {
    setIsLoading(true);
    console.log("Loading...");
    // console.log("essay", essay);
    const axiosRequestBuilder = new AxiosRequestBuilder();
    const response = await axiosRequestBuilder
      .setMethod("post")
      .setUrl(`/Essay/Teacher`)
      .setResponseType("json")
      .setBody<EssayCreate>(essay)
      .send<EssayCreate>();
    if (response) {
      setCreatedEssay(response.data);
      setOpenDialog(true);
      setSend(false);
      setSuccess_(true);
    }
  } catch (error: unknown) {
    setSend(false);
    console.error(error);
    if (typeof error === "object" && error !== null && "response" in error) {
      const { response } = error as { response?: { status: number } };
      if (response && response.status !== 401 && response.status !== 404) {
        setSuccess_(false);
      }
    }
  } finally {
    await sleep(5000);
    setIsLoading(false);
    setOpen_(true);
  }
};

export const saveEssay_ = async (
  essay: EssaySave,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSave: (value: React.SetStateAction<boolean>) => void,
  setSuccess: (value: React.SetStateAction<boolean>) => void,
  setLastSavedText: (value: React.SetStateAction<string>) => void,
  setIsModalVisible: (value: React.SetStateAction<boolean>) => void,
  sendMail: (props: Props) => void, // Corrigido
  isAutoSave: boolean,
  setIsAutoSave: (value: React.SetStateAction<boolean>) => void,
  setIsErrorTimer: (value: React.SetStateAction<boolean>) => void,
  setIsModalErrorVisible: (value: React.SetStateAction<boolean>) => void,
  setButtonDisabled: (value: React.SetStateAction<boolean>) => void,
  setButtonTextDisabled: (value: React.SetStateAction<boolean>) => void,
  setButtonTitle: (value: React.SetStateAction<string>) => void,
  setloading: (value: React.SetStateAction<boolean>) => void,
  props: React.PropsWithChildren<Props>,
  text: string
) => {
  try {
    setIsLoading(true);
    console.log("Loading...");
    const axiosRequestBuilder = new AxiosRequestBuilder();
    const response = await axiosRequestBuilder
      .setMethod("post")
      .setUrl(`/Essay/student/SaveEssay`)
      .setResponseType("json")
      .setBody<EssaySave>(essay)
      .send<EssaySave>();
    if (response) {
      setSave(false);
      setSuccess(true);
      //console.log(isAutoSave);
      setLastSavedText(text);
      if (!isAutoSave) {
        setIsModalVisible(true);
        sendMail(props);
      }
      setloading(false);
      setIsAutoSave(false);
      setIsErrorTimer(false);
    }
  } catch (error: unknown) {
    if (typeof error === "object" && error !== null && "response" in error) {
      const { response } = error as { response?: { status: number } };
      if (response && response.status !== 401 && response.status !== 404) {
        if (!isAutoSave) {
          setIsModalErrorVisible(true);
          setSave(false);
          setButtonDisabled(true);
          setButtonTextDisabled(true);
          setButtonTitle("SALVAR RASCUNHO");
        }
        console.error(error);
        setloading(false);
      }
    }
  } finally {
    await sleep(3000);
    setIsLoading(false);
  }
};

export const LogoutRedacao = () => {
  localStorage.clear();
};

interface Email {
  email: string;
}

export const LogoutGoogle = async (email: string) => {
  const mail: Email = { email };
  console.log(mail, "mail");
  try {
    // setIsLoading(true);
    const axiosRequestBuilder = new AxiosRequestBuilder();
    const response = await axiosRequestBuilder
      .setMethod("post")
      .setUrl(`/Auth/logoutGoogle`)
      .setResponseType("json")
      .setBody<Email>(mail)
      .send<Email>();
    if (response) {
      console.log(response.data);
    }
  } catch (error: unknown) {
    console.error(error);
    if (typeof error === "object" && error !== null && "response" in error) {
      const { response } = error as { response?: { status: number } };
      if (response && response.status !== 401 && response.status !== 404) {
        console.log(response);
      }
    }
  }
};

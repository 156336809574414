export const initialAuthState = {
  isLoggedIn: false,
  username: "",
  profile: {},
  error: "",
};

export const authReducer = (state, action) => {
  //CheckToken()
  switch (action.type) {
    case "LOGIN":
      return {
        isLoggedIn: true,
        username: action.payload.username,
        profile: action.payload.profile,
        error: "",
      };
    case "LOGIN_ERROR":
      return {
        isLoggedIn: false,
        username: "",
        profile: "",
        error: action.payload.error,
      };
    case "LOGOUT":
      return {
        isLoggedIn: false,
        username: "",
        profile: "",
        error: "",
      };
    default:
      return state;
  }
};

import React from "react";

const Modal = ({
  type,
  id = "modal",
  onClose = () => {},
  onConfirm = () => {},
  children,
}) => {
  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };
  if (type === "success") {
    return (
      <div
        id={id}
        className="modal"
        onClick={handleOutsideClick}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: "rgba(0,0,0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            backgroundColor: "var(--white)",
            color: "var(--blue-light)",
            width: "45%",
            height: "30%",
            borderRadius: "0.28rem",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem auto",
              marginTop: "6%",
            }}
          >
            {children}
          </div>
          <button
            className="close"
            onClick={onClose}
            style={{
              backgroundColor: "var(--green)",
              border: "none",
              borderRadius: "0.28rem",
              marginTop: "8%",
              width: "10rem",
              height: "3rem",
              cursor: "pointer",
              fontSize: "20px",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              fontWeight:"bold",
            }}
          >
            {" "}
            OK{" "}
          </button>
        </div>
      </div>
    );
  } else if (type === "danger") {
    return (
      <div
        id={id}
        className="modal"
        onClick={handleOutsideClick}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: "rgba(0,0,0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            backgroundColor: "var(--white)",
            color: "var(--blue-light)",
            width: "65%",
            height: "33%",
            borderRadius: "0.28rem",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem auto",
              marginTop: "6%",
            }}
          >
            {children}
          </div>
          <button
            className="close"
            onClick={onClose}
            style={{
              backgroundColor: "red",
              border: "none",
              borderRadius: "0.28rem",
              outline: "none",
              width: "7rem",
              height: "3rem",
              cursor: "pointer",
              fontSize: "20px",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "8%",
              fontWeight:"bold",
            }}
          >
            {" "}
            OK{" "}
          </button>
        </div>
      </div>
    );
  } else if (type === "alert") {
    return (
      <div
        id={id}
        className="modal"
        onClick={handleOutsideClick}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: "rgba(0,0,0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            backgroundColor: "var(--white)",
            color: "var(--blue-light)",
            width: "50%",
            height: "30%",
            borderRadius: "0.28rem",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem auto",
              marginTop: "6%",
            }}
          >
            {children}
          </div>
          <button
            className="close"
            onClick={onClose}
            style={{
              backgroundColor: "orange",
              border: "none",
              borderRadius: "0.28rem",
              outline: "none",
              width: "7rem",
              height: "3rem",
              cursor: "pointer",
              fontSize: "20px",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "8%",
              fontWeight:"bold",
            }}
          >
            {" "}
            OK{" "}
          </button>
        </div>
      </div>
    );
  } else if (type === "default") {
    return (
      <div
        id={id}
        className="modal"
        onClick={handleOutsideClick}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: "rgba(0,0,0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            backgroundColor: "var(--white)",
            color: "var(--blue-light)",
            width: "50%",
            height: "30%",
            borderRadius: "0.28rem",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem auto",
              marginTop: "4rem",
            }}
          >
            {children}
          </div>
          <button
            className="close"
            onClick={onClose}
            style={{
              backgroundColor: "var(--blue-light)",
              border: "none",
              borderRadius: "0.28rem",
              outline: "none",
              width: "7rem",
              height: "3rem",
              cursor: "pointer",
              fontSize: "20px",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px auto",
              fontWeight:"bold",
            }}
          >
            {" "}
            OK{" "}
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div
        id={id}
        className="modal"
        onClick={handleOutsideClick}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: "rgba(0,0,0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            backgroundColor: "#fff",
            color: "var(--blue-light)",
            width: "56%",
            height: "35%",
            borderRadius: "0.28rem",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem auto",
              marginTop: "4rem",
            }}
          >
            {children}
          </div>
          <button
            className="close"
            onClick={onClose}
            style={{
              backgroundColor: "var(--red)",
              border: "none",
              borderRadius: "0.28rem",
              outline: "none",
              width: "9rem",
              height: "3rem",
              cursor: "pointer",
              fontSize: "20px",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px auto",
              fontWeight:"bold",
              textTransform:"uppercase",
              padding:"0px 10px"
            }}
          >
            {" "}
            Cancelar{" "}
          </button>
          <button
            className="close"
            onClick={onConfirm}
            style={{
              backgroundColor: "var(--green)",
              border: "none",
              borderRadius: "0.28rem",
              outline: "none",
              width: "9rem",
              height: "3rem",
              cursor: "pointer",
              fontSize: "20px",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px auto",
              marginLeft: "5%",
              marginBottom: "5px",
              fontWeight:"bold",
              textTransform:"uppercase",
              padding:"0px 10px"
            }}
          >
            {" "}
            Confirmar{" "}
          </button>
        </div>
      </div>
    );
  }
};

export default Modal;

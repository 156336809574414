import React, { useState, useEffect } from "react";
import { Topbar } from "../../../component/Topbar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { apiSecure } from "../../../domain/api";

import ThemesAutocompleteDefault from "../../../component/ThemesAutocompleteDefault";
import ThemesGenre from "../../../component/ThemesGenre";
import ThemesType from "../../../component/ThemesType";

function NewEssay() {
  const classes = useStyles();
  const [type, setType] = useState();
  const [gender, setGender] = useState();
  const [themeId, setThemeId] = useState(0);
  const [theme, setTheme] = useState();
  const [loaded, setLoad] = useState(false);
  const [motivatingTexts, setMotivatingTexts] = useState([]);

  var prop = 0;

  useEffect(() => {
    setThemeId(prop);
  }, [prop]);

  useEffect(() => {
    getThemeDetails();

    function getThemeDetails() {
      apiSecure
        .get("/Theme/" + themeId)
        .then(function (response) {
          setTheme(response.data);
          getMotivatingTexts(themeId);
          setLoad(true);
        })
        .catch(function (error) {});
    }

    function getMotivatingTexts(themeId) {
      apiSecure
        .get("Theme/MotivatingTexts?themeId=" + themeId)
        .then(function (response) {
          setMotivatingTexts(response.data);
        })
        .catch(function (error) {});
    }
  }, [themeId]);

  function onSubmitTheme(formState) {
    prop = formState;
    setThemeId(prop);
  }
  function onSubmitType(submitType) {
    setType(submitType);
  }

  function onSubmitGender(submitGenre) {
    setGender(submitGenre);
  }

  function onSubmit(formState) {
    apiSecure
      .post("/Essay/student?themeId=" + themeId)
      .then(function (response) {
        return (window.top.location.href =
          "/student/essay/" + response.data.token);
      })
      .catch(function (error) {});
  }

  return (
    <>
      <Topbar profile="8" menu="newEssay" />
      <div
        style={{
          margin: "2% 1% 0 1%",
          backgroundColor: "var(--blue-light)",
          color: "var(--white)",
          width: "98%",
          padding: "1%",
          borderTopLeftRadius: "0.25rem",
          borderTopRightRadius: "0.25rem",
          textAlign: "center",
        }}
      >
        <h2 style={{ color: "var(--white)" }}>TREINAR REDAÇÃO</h2>
      </div>
      <div
        style={{
          margin: "0 1% 1% 1%",
          backgroundColor: "white",
          width: "98%",
          padding: "2% 0px",
          borderBottomLeftRadius: "0.25rem",
          borderBottomRightRadius: "0.25rem",
        }}
      >
        <div class={classes.centered}>
          <ThemesType onSubmit={onSubmitType} />
          <ThemesGenre onSubmit={onSubmitGender} typeId={type} />
          <ThemesAutocompleteDefault
            onSubmit={onSubmitTheme}
            type={type}
            gender={gender}
            owner={false}
          />
        </div>

        <div class={classes.centered}>
          {loaded && (
            <>
              <center>
                <div
                  style={{
                    borderTop: "1px solid var(--blue-light)",
                    width: "100%",
                  }}
                ></div>
              </center>
              <Typography
                variant="h6"
                style={{
                  marginTop: 35,
                  justifyContent: "center",
                  display: "flex",
                  color: "var(--blue-light)",
                }}
              >
                {theme.title}
              </Typography>
              <br></br>
              <Typography
                alignJustify
                variant="body1"
                style={{ textAlign: "justify" }}
              >
                {theme.description}
              </Typography>

              {motivatingTexts.length > 0 && (
                <>
                  <Typography
                    variant={"h6"}
                    style={{ marginTop: 35, color: "var(--blue-light)" }}
                  >
                    {" "}
                    TEXTOS DE APOIO
                  </Typography>
                  <div style={{ marginTop: 35 }}>
                    {motivatingTexts.map((e) => (
                      <>
                        {e.isFile && (
                          <img
                            alt=""
                            style={{
                              width: "100%",
                            }}
                            src={e.text}
                          />
                        )}
                        {!e.isFile && (
                          <div
                            style={{ textAlign: "justify", textIndent: 50 }}
                            dangerouslySetInnerHTML={{
                              __html: e.text,
                            }}
                          ></div>
                        )}
                        <Divider style={{ margin: 30 }} />
                      </>
                    ))}
                  </div>
                </>
              )}
              <center>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttons}
                  onClick={() => onSubmit()}
                  style={{ width: "90%" }}
                >
                  Iniciar Redação
                </Button>
              </center>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default NewEssay;

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    margin: "auto",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "90%",
    },
  },
  buttons: {
    margin: "2% 0",
    background: "var(--green)",
  },

  inputs: {
    margin: "auto",
  },
}));

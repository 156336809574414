import React, { useEffect, useState } from 'react'
import { Content, ClassItem } from './styles'
import { apiSecure } from '../../../domain/api'
import { useHistory } from 'react-router'
import { Button, Stack } from '@mui/material'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { Topbar } from 'component/Topbar'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Description } from '@material-ui/icons'

interface Class {
  token: string
  essayId: number
  classroomDescription: string
  type: string
  genre: string
  description: string
  assignedClassrooms: number
  classroomId: string
  creationDate: Date
  classroomName : string
  history: {
    location: {
      state: {
        classroomId: string
      }
    }
  }
}

interface Item {
  classroomId: string
  essayId: number
  data: Date
  topRightText?: string
  token: string
  footer: string
  title: string
  description: string
  topRight: string
  topLeft: string
  classroom: string
  classroomName: string
}

interface Student {
  cgm: number
  studentId: number
  classroomDescription: string
  email: string
  essayLinked: boolean
  name: string
  statusDescription: number
  status: number
  token: string
  essayId: number
  classroom: string
  classroomId: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: '1%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}


export function Class(props: Class) {
  const [updateEffect, setUpdateEffect] = useState(false);
  const [classe, setClasses] = useState([])  
  var path = '';
  let history = useHistory()

  function isDeleted(status: number, props: Class){
    const input = document.getElementById('hiddenStatus') as HTMLInputElement;

    if (status == 0){
      console.log('ativo');
      input.value = '0';
    }
    else{
      console.log('inativo');
      input.value = '1';  
    }
     
    setUpdateEffect(!updateEffect);
  }

  useEffect(() => {
    const input = document.getElementById("hiddenStatus") as HTMLInputElement;
    if (input.value == "1") {
      //INATIVO
      path = "/Essay/teacher/l/historyInactive/";
      document.getElementById("btnAtivar")!.style.color = "var(--blue-light)";
      document.getElementById("btnAtivar")!.style.fontWeight = "normal";
      document.getElementById("btnAtivar")!.style.background = "var(--background)";
      document.getElementById("btnInativar")!.style.color = "var(--background)";
      document.getElementById("btnInativar")!.style.fontWeight = "bold";
      document.getElementById("btnInativar")!.style.background = "var(--blue-light)";
    } else {
      //ATIVO
      path = "/Essay/teacher/l/history/";
      document.getElementById("btnAtivar")!.style.color = "var(--background)";
      document.getElementById("btnAtivar")!.style.fontWeight = "bold";
      document.getElementById("btnAtivar")!.style.background = "var(--blue-light)";
      document.getElementById("btnInativar")!.style.color = "var(--blue-light)";
      document.getElementById("btnInativar")!.style.fontWeight = "normal";
      document.getElementById("btnInativar")!.style.background = "var(--background)";
    }
    
    apiSecure
      .get(   
        path + props.history.location.state.classroomId
      )
      .then(function (response) {
        setClasses(response.data)
      })
      .catch(function (error) {
        console.error(error)
      });

  }, [updateEffect])

  return (
    <>
      <Topbar profile="10" menu="home"></Topbar>

      <Content>
        <input type='text' id='hiddenStatus' style={{display: "none"}}></input>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
          <div>
            <button
              id= "btnAtivar"
              style={{
                display: 'inline',
                background: 'var(--blue-light)',
                color: 'primary',
                margin: '0px 5px 0px 0px',
                padding: '5px', 
                border: 'none',
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
                fontSize: '100%',
              }}
              onClick={() => isDeleted(0, props)}
              >
              TEMAS ATIVOS
            </button>
          </div>
          <div>            
            <button
            id="btnInativar"
              style={{
                display: 'inline',
                background: 'var(--blue-light)',
                color: 'primary',
                margin: '0px 0px 0px 0px',
                padding: '5px', 
                border: 'none',
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
                fontSize: '100%',
              }}
              onClick={() => isDeleted(1, props)}
              >
              TEMAS INATIVOS
            </button>
          </div>
        </div>
        <div style={{ borderTop: "3px solid var(--blue-light)", width: '100%'}}></div>

        {classe &&
          classe.map((e: Class) => (
            <ClassroomItem
              key={e.token}
              essayId={e.essayId}
              classroom={e.classroomDescription}
              title={e.type + ' - ' + e.genre}
              classroomId={props.history.location.state.classroomId}
              description={e.description}
              classroomName={e.classroomName}
              topLeft={
                'Atribuida a ' +
                e.assignedClassrooms +
                ' alunos na turma ' + e.classroomName
              }
              token={e.token}
              data={props.creationDate}
              footer={''}
              topRight={''}
              />
              ))}
        {classe?.length === 0 && 
          <p style={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginBottom:'30px'}}>Não existem temas</p>
        }
        
        <button
          style={{
            display: 'block',
            background: 'var(--green)',
            color: 'var(--white)',
            margin: '0 30%',
            width: '100%',
            height: '3rem',
            border: 'none',
            borderRadius: '0.25rem',
            fontSize: '100%',
            fontWeight: 'bold',
          }}
          onClick={() => history.push('/teacher/home')}
          >
          {' '}
          VOLTAR{' '}
        </button>
      </Content>
    </>
  )
}

const ClassroomItem = (props: Item) => {
  const classes = useStyles()
  const [studentsModal, setStudentsModal] = useState(false)
  
  const [students, setStudents] = useState<Student[]>([])
  let history = useHistory()
  
  function handleCloseDialog() {
    setStudentsModal(false)
  }

  useEffect(() => {
    if (studentsModal === true) {
      apiSecure
        .get('/Essay/teacher/l/student/' + props.essayId, {
          params: {
            classroomId: props.classroomId,
          },
        })
        .then(function (response) {
          setStudents(response.data)
          console.log(response.data)
        })
        .catch(function (error) {
          console.error(error)
        })
      }
      if (studentsModal === false) {
        setStudentsModal(false)
    }
  }, [studentsModal, props.classroomId, props.essayId])

  return (
    <>
      {!props.classroomId && (
        <div className={classes.root}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Alert severity="info"> Nenhuma proposta encontrada...</Alert>
            <Button
              variant="outlined"
              onClick={() => history.push('/teacher/essay/new')}
            >
              Cadastrar proposta
            </Button>
          </Stack>
        </div>
      )}
      {props.classroomId && (
        <ClassItem
          onClick={() =>
            history.push({
              pathname:
                '/teacher/class/' +
                props.classroomId +
                '/student/' +
                props.token,
              state: { classroomId: props.classroomId, essayId: props.essayId },
            })
          }
        >
          <p>{props.topLeft}</p>

          <h3>{props.topRightText}</h3>
          <p>{props.title}</p>

          <p>{props.description}</p>
          <p>{props.footer}</p>
          
          <button
            style={{
              verticalAlign: 'middle',
            }}
            onClick={(e) => {
              navigator.clipboard.writeText(
                'https://redacao.pr.gov.br/essay/students/' + props.token
              )
              e.stopPropagation()
            }}
          >
            <i className="material-icons">link</i>
            &nbsp;COPIAR LINK
          </button>

          <button
            style={{
              verticalAlign: 'middle',
            }}
            onClick={(e) => {
              history.push('/teacher/essay/' + props.token, {
                id: props.essayId,
              })
              e.stopPropagation()
            }}
          >
            <i className="material-icons">edit</i>
            &nbsp;EDITAR
          </button>
        </ClassItem>
      )}
    </>
  )
}

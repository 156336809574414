import * as React from "react";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { Container } from "./styled";
import "./style.css";

type AlertColor = /*unresolved*/ any;

interface Props {
  msg: string;
  open: boolean;
  severity: AlertColor | undefined;
  href?: string | null;
  cor?: string;
  // setOpen?: (value: React.SetStateAction<boolean | null>) => void;
  setOpen?: (value: React.SetStateAction<boolean>) => void; // Corrigido
  setSaveStatus?: (value: React.SetStateAction<boolean | null>) => void;
}

export default function Toast(props: Props) {
  const { msg, open, severity, href, setOpen, setSaveStatus, cor } = props;

  const reload = () => {
    window.location.reload();
  };

  const close = () => {
    if (setOpen) {
      setOpen(false);
    }
    if (setSaveStatus) {
      setSaveStatus(null);
    }
  };

  return (
    <Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          marginTop: "20%",
          width: "355px",
        }}
      >
        <Box>
          <Alert severity={severity} variant="filled">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Box sx={{ fontSize: "10pt" }}>{msg}</Box>
              <Box>
                {href && severity === "success" && (
                  <IconButton href={href} onClick={reload}>
                    <ClearIcon
                      sx={{ cursor: "pointer", color: cor ? cor : "#fff" }}
                    />
                  </IconButton>
                )}
                {severity === "error" && (
                  <IconButton onClick={close}>
                    <ClearIcon
                      sx={{ cursor: "pointer", color: cor ? cor : "#fff" }}
                    />
                  </IconButton>
                )}
                {href === null && severity === "success" && (
                  <IconButton onClick={close}>
                    <ClearIcon
                      sx={{ cursor: "pointer", color: cor ? cor : "#fff" }}
                    />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Alert>
        </Box>
      </Snackbar>
    </Container>
  );
}

import { useState, useEffect } from "react";
import { Topbar } from "component/Topbar";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "../../../component/Card";
import { useHistory } from "react-router-dom";
import {
  GetRecent,
  GetPending,
  GetFinished,
  GetPendingWithTeacher,
} from "domain/essayStudent/service";
import "fontsource-roboto";
import moment from "moment";
import "moment/min/locales";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { DateTime } from "luxon";

import { Container, Accordeon } from "./styles";
import React from "react";

interface Student {
  token: string;
  statusDescription: string;
  essayId: number;
  status: number;
  description: string;
  type: number;
  genre: number;
  score: number;
  reviewScore: number;
  selfCreated: boolean;
  teacherName: string;
  endDate: DateTime;
  startDate: DateTime;
  updatedAt: DateTime;
  createdAt: DateTime;
  deliveredAt: DateTime;
  reviewedAt: DateTime;
}

export default function StudentsHome() {
  let history = useHistory();
  const classes = useStyles();
  const [students, setStudents] = useState<Student[]>([]);
  const [waiting, setWaiting] = useState<Student[]>([]);
  const [recentlyFinished, setrecentlyFinished] = useState<Student[]>([]);
  const [finished, setFinished] = useState<Student[]>([]);
  const [accordeonOpen, setAccordeonOpen] = useState<boolean>(false);
  const [delayedEssay, setDelayedEssay] = useState([]);
  const [essay, setEssay] = useState([]);

  var delayed: any = [];
  var onScheduled: any = [];
  var CurrentDate = moment().format();

  useEffect(() => {
    students &&
      students.map((e) => {
        if (moment(e.createdAt).format() < moment().format()) {
          delayed.push(e);
        } else {
          onScheduled.push(e);
        }
      });
    setDelayedEssay(delayed);
    setEssay(onScheduled);
  }, [students]);

  //console.log("d", delayed);
  //console.log("o", onScheduled);
  function GetAbbreviatedName(str: string) {
    if (str == null || str === undefined || str === "") {
      return "";
    }
    let spaces = String(str).split(" ");
    if (spaces.length === 0) {
      return "";
    }

    let name = spaces[0];
    if (spaces.length === 1) {
      return name;
    }
    let lastName = spaces[1];
    let firstLetter = lastName.substr(0, 1);

    return name + " " + firstLetter + ".";
  }

  interface Score {
    score: number;
    reviewScore: number;
    selfCreated: boolean;
  }
  function GetScore(e: Score) {
    //console.log(e);
    let score = e.score *2.5;
    let maxScore = e.selfCreated ? "200" : "1000";
    let reviewScore = e.reviewScore ? e.reviewScore : 0;
    return `${Number(score + reviewScore).toFixed(0)} / ${maxScore}`;
  }

  // 1	Pendente
  // 2	Rascunho
  // 3	Correção online
  // 4	Enviada para o professor corrigir
  // 5	Devolvida para refazer
  // 6	Concluído
  function GetIconColor(status: number) {
    switch (status) {
      case 1:
      case 2:
      case 3:
        return "#f50057";
      case 5:
        return "darkred";
      case 6:
        return "darkgreen";
      default:
        return "darkgreen";
    }
  }
  function GetIconName(status: number) {
    switch (status) {
      case 1:
        return "add_circle";
      case 4:
        return "check_circle";
      case 5:
        return "error";
      case 6:
        return "offline_pin";
      default:
        return "remove";
    }
  }

  return (
    <>
      <Topbar profile="8" menu="home" />
      <GetPending callback={(e: Student[]) => setStudents(e)} />
      <GetRecent callback={(e: Student[]) => setrecentlyFinished(e)} />
      <GetPendingWithTeacher callback={(e: Student[]) => setWaiting(e)} />
      <GetFinished callback={(e: Student[]) => setFinished(e)} />
      <div>
        <div>
          <div
            style={{
              backgroundColor: "var(--blue-light)",
              margin: "1% 1% 0 1%",
              padding: "0.5%",
              borderTopLeftRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 className="titles" style={{ color: "var(--white)" }}>
              PENDENTES
            </h2>
          </div>
          <Container>
            {essay.length > 0 &&
              essay.map((e: Student) => (
                <Card
                  borderBottom="var(--red)"
                  redirectUrl={"/student/essay/" + e.token}
                  leftTop={e.statusDescription.toUpperCase()}
                  iconName={GetIconName(e.status)}
                  iconColor={GetIconColor(e.status)}
                  key={e.token}
                  description={e.description}
                  bottomLeft={`${e.type} - ${e.genre}`}
                  bottomRight={`Criado por ${
                    e.selfCreated ? "mim" : GetAbbreviatedName(e.teacherName)
                  }`}
                  rightTop={
                    e.selfCreated
                      ? ""
                      : `Encerra ${moment(e.endDate)
                          .utc(true)
                          .utcOffset("-03:00")
                          .locale("pt-br")
                          .endOf("day")
                          .fromNow()}`
                  }
                  rightTopTooltip={moment(e.endDate)
                    .utc(true)
                    .utcOffset("-03:00")
                    .locale("pt-br")
                    .format("LLLL")}
                  bottomRightTooltip={`${moment(
                    e.updatedAt > e.createdAt ? e.updatedAt : e.createdAt
                  )
                    .utc(true)
                    .utcOffset("-03:00")
                    .locale("pt-br")
                    .startOf("second")
                    .fromNow()}`}
                  visibility={false}
                />
              ))}

            {delayedEssay.length > 0 && (
              // <Accordeon
              //   active={accordeonOpen}
              //   onClick={() => setAccordeonOpen(!accordeonOpen)}
              // >
              //   <h3 style={{ textAlign: "center" }}>
              //     REDAÇÕES EM ATRASO - CLIQUE PARA VISUALIZAR
              //   </h3>

              //{delayedEssay.length > 0 && (
              <>
                {delayedEssay.map((e: Student) => (
                  <Card
                    borderBottom="var(--red)"
                    redirectUrl={"/student/essay/" + e.token}
                    leftTop={e.statusDescription.toUpperCase()}
                    iconName={GetIconName(e.status)}
                    iconColor={GetIconColor(e.status)}
                    key={e.token}
                    description={e.description}
                    bottomLeft={`${e.type} - ${e.genre}`}
                    bottomRight={`Criado por ${
                      e.selfCreated ? "mim" : GetAbbreviatedName(e.teacherName)
                    }`}
                    rightTop={
                      e.selfCreated
                        ? ""
                        : `Encerra ${moment(e.endDate)
                            .utc(true)
                            .utcOffset("-03:00")
                            .locale("pt-br")
                            .endOf("day")
                            .fromNow()}`
                    }
                    rightTopTooltip={moment(e.endDate)
                      .utc(true)
                      .utcOffset("-03:00")
                      .locale("pt-br")
                      .format("LLLL")}
                    bottomRightTooltip={`${moment(
                      e.updatedAt > e.createdAt ? e.updatedAt : e.createdAt
                    )
                      .utc(true)
                      .utcOffset("-03:00")
                      .locale("pt-br")
                      .startOf("second")
                      .fromNow()}`}
                    visibility={false}
                  />
                ))}
              </>
              //)}
              //</Accordeon>
            )}
            {students.length === 0 && (
              <p style={{ margin: 10 }}>Não há redações pendentes.</p>
            )}
          </Container>
        </div>
        <div>
          <div
            style={{
              backgroundColor: "var(--blue-light)",
              margin: "1% 1% 0 1%",
              padding: "0.5%",
              borderTopLeftRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <h2 style={{ color: "var(--white)" }}>AGUARDANDO CORREÇÃO</h2>
            </center>
          </div>
          <Container>
            {waiting.length > 0 &&
              waiting.map((e: Student) => (
                <Card
                  borderBottom="var(--blue-light)"
                  key={e.token}
                  leftTop={`Aguardando ${GetAbbreviatedName(e.teacherName)}`}
                  iconName={GetIconName(e.status)}
                  iconColor={GetIconColor(e.status)}
                  description={e.description}
                  bottomLeft={`${e.type} - ${e.genre}`}
                  rightTop={`Entregue ${moment(e.deliveredAt)
                    .utc(true)
                    .utcOffset("-03:00")
                    .locale("pt-br")
                    .startOf("second")
                    .fromNow()}`}
                  rightTopTooltip={moment(e.deliveredAt)
                    .utc(true)
                    .utcOffset("-03:00")
                    .locale("pt-br")
                    .format("DD/MM/YYYY - HH:mm")}
                  redirectUrl={"/student/essay/" + e.token}
                />
              ))}

            {waiting.length === 0 && (
              <p style={{ margin: 10 }}>Nenhum registro encontrado</p>
            )}
          </Container>
        </div>

        <div>
          <div
            style={{
              backgroundColor: "var(--blue-light)",
              margin: "1% 1% 0 1%",
              padding: "0.5%",
              borderTopLeftRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <h2 style={{ color: "var(--white)" }}>CONCLUÍDAS</h2>
            </center>
          </div>
          <Container>
            {finished.length > 0 &&
              finished.map((e) => (
                <Card
                  borderBottom="var(--green)"
                  key={e.token}
                  iconName={GetIconName(e.status)}
                  iconColor={GetIconColor(e.status)}
                  leftTop={`Concluído por ${GetAbbreviatedName(
                    e.teacherName
                  )} ${moment(e.reviewedAt ?? e.deliveredAt)
                    .utc(true)
                    .utcOffset("-03:00")
                    .locale("pt-br")
                    .startOf("minute")
                    .fromNow()}`}
                  leftTopTooltip={`${moment(e.reviewedAt ?? e.deliveredAt)
                    .utc(true)
                    .utcOffset("-03:00")
                    .locale("pt-br")
                    .startOf("minute")
                    .format("DD/MM/YYYY - HH:mm")}`}
                  description={e.description}
                  bottomLeft={`${e.type} - ${e.genre}`}
                  rightTop={`Nota: ${GetScore(e)}`}
                  rightTopSize={15}
                  rightTopTooltip={"Somente redações de professor tem peso 100"}
                  redirectUrl={"/student/essay/" + e.token}
                />
              ))}
            {finished.length === 0 && (
              <p>
                Ainda não encontramos nenhuma redação concluída no seu
                histórico.
              </p>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    flexFlow: "row ",
    justifyContent: "left",
    flexBasis: "80%",
    maxWidth: "100%",
  },
  left: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "24px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "24px",
    },
    "@media (min-width: 1280px)": {
      float: "right",
      margin: theme.spacing(3),
    },
  },
  margin: {
    margin: 20,
  },
  newEssay: {
    position: "fixed",
    float: "right",
    bottom: 30,
    right: 30,
    width: 200,
    fontSize: 13,
    borderRadius: 30,
    height: 40,

    [theme.breakpoints.down("sm")]: {
      width: 100,
      fontSize: 11,
    },
  },
}));

const settings = {
  infinite: false,
  // centerPadding: "70px",
  // adaptiveHeight: true,
  // draggable: true,
  // swipe:true,
  // touchMove:true,
  // onSwipe: '',
  // swipeToSlide: true,

  dots: true,
  speed: 500,
  nextArrow: <ArrowForward color={"primary"} />,
  prevArrow: <ArrowBack color={"primary"} />,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

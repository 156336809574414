import { Topbar } from "../../../component/Topbar";
import { Typography } from "@material-ui/core";
import logo from "../../../assets/redacao.png";
import { Container } from "./styles.js";

export default function Info() {
  return (
    <>
      <Topbar profile="10" menu="info" />
      <div
        style={{
          margin: "1%",
          textAlign: "center",
          backgroundColor: "#FFF",
          padding: "2%",
          borderRadius: "0.25rem",
        }}
      >
        <center>
          <div style={{ marginTop: 35, fontSize: "1.5rem" }}>
            <img alt="Redação Logo" src={logo} width="200" />
          </div>
        </center>
        <Container>
          <div>
            <Typography>
              <p className="pSimple2">
                Redação Paraná é uma plataforma de produção textual que integra
                tecnologia ao trabalho do professor, com objetivo de subsidiar a
                promoção da escrita de forma dinâmica e inovadora. Diversas
                propostas com temas e textos motivadores podem ser encontrados
                na plataforma, mas também podem ser registrados a partir da
                necessidade de cada professor. Realizada a proposta pelo
                professor, o desenvolvimento do texto pelo estudante conta com o
                auxílio de recursos como a correção online. A inteligência
                artificial corrige a estrutura gramatical e o professor atua na
                orientação, na construção da estrutura e argumentação da redação
                elaborada pelo estudante, com diversas possibilidades de
                reescrita, até a atribuição de uma nota.
              </p>
              <br />
              <hr></hr>
              <br />
              <p className="pSimple">Versão: 1.0.8</p>
              <p className="pSimple3">Data: 03/06/2024</p>
              <br />
              <ul className="pSimple4">
                {/* <li>
                  Inclusão da ferramenta de avaliação diagnóstica para professores e diretores;
                </li> */}
                <li>
                  Inclusão da ferramenta para salvamento offline.
                </li>
                <li>
                  Inclusão do botão para inserir parágrafos.
                </li>
              </ul>
              <br />
              <hr></hr>
              <br />
              <p className="pSimple">Versão: 1.0.7</p>
              <p className="pSimple3">Data: 21/03/2024</p>
              <br />
              <ul className="pSimple4">
                <li>
                  Devido a instabilidades, o salvamento automático foi desabilitado.
                </li>
              </ul>
              <br />
              <hr></hr>
              <br />
              <p className="pSimple">Versão: 1.0.6</p>
              <p className="pSimple3">Data: 07/03/2024</p>
              <br />
              <ul className="pSimple4">
                <li>
                  Atualização das ferramentas de prevenção de copiar e colar de
                  textos externos/internos;
                </li>
                <li>Alteração da paragrafação: de #pp para TAB;</li>
                <li>Inclusão do salvamento automático de redações.</li>
              </ul>
              <br />
              <hr></hr>
              <br />
              <p className="pSimple">Versão: 1.0.5</p>
              <p className="pSimple3">Data: 01/08/2023</p>
              <br />
              <ul className="pSimple4">
                <li>Inclusão da ferramenta de acessibilidade vLibras;</li>
              </ul>
              <br />
              <hr></hr>
              <br />
              <p className="pSimple">Versão: 1.0.4</p>
              <p className="pSimple3">Data: 10/07/2023</p>
              <br />
              <ul className="pSimple4">
                <li>
                  Alteração da data final padrão da redação de 15 dias para 30
                  dias;
                </li>
                <li>
                  Correção do erro na exibição das redações pendentes (para
                  alunos);
                </li>
                <li>
                  Inclusão da ferramenta de edição de texto para a criação de
                  propostas (para professores);
                </li>
                <li>Inclusão do botão de exportar notas (para professores).</li>
              </ul>
              <br />
              <hr></hr>
              <br />
              <p className="pSimple">Versão: 1.0.3</p>
              <p className="pSimple3">Data: 12/05/2023</p>
              <br />
              <ul className="pSimple4">
                <li>Ajustes de responsividade para dispositivos mobile;</li>
                <li>
                  Alteração geral no layout e coloração da plataforma -
                  realizada padronização dos elementos;
                </li>
                <li>
                  Alteração do cabeçalho da plataforma, adicionado o menu em
                  formato cascata;
                </li>
                <li>Implementação do rodapé;</li>
                <li>Implementação da aba Temas Inativos (para professores);</li>
                <li>
                  Implementação da possibilidade de inativar um tema (para
                  professores);
                </li>
                <li>
                  Melhoria na visualização dos critérios de avaliação de texto -
                  agora ao lado da redação (para professores);
                </li>
                <li>
                  Melhoria na visualização dos alunos dentro das turmas (para
                  professores);
                </li>
                <li>
                  Melhoria na visualização das instruções de escrita dos gêneros
                  textuais (para alunos).
                </li>
              </ul>
              <br />
              <hr></hr>
              <br />
              <p className="pSimple">Versão: 1.0.2</p>
              <p className="pSimple3">Data: 11/07/2022</p>
              <br />
              <ul className="pSimple4">
                <li>
                  Agrupar os erros de ortografia e acentuação em uma única
                  categoria "ortografia";
                </li>
                <li>
                  Ao acessar uma proposta de redação criada, o professor vê
                  apenas as turmas para as quais a proposta foi atribuída;
                </li>
                <li>
                  Ativação do CTRL + C para que o professor possa copiar e colar
                  no campo 'Devolutiva' para dar o feedback em um determinado
                  parágrafo;
                </li>
                <li>
                  Limitação da quantidade de caracteres - 100 caracteres - no
                  título da redação;
                </li>
                <li>
                  Permitir acesso à Plataforma aos alunos com “matrícula em
                  integralização” e “matrícula em dependência”;
                </li>
                <li>
                  Possibilidade de Correção das Propostas por parte dos
                  Diretores e Pedagogos em caso de ausência de afastamento do
                  professor titular.
                </li>
              </ul>
              <br />
              <hr></hr>
              <br />
              <p className="pSimple">Versão: 1.0.1</p>
              <p className="pSimple3">Data: 06/07/2022</p>
              <br />
              <ul className="pSimple4">
                <li>
                  Alteração na nomenclatura das turmas no momento da proposição
                  das redações, considerando o curso, turno e escola;
                </li>
                <li>
                  Correção da data padrão utilizada na proposição das redações
                  para 15 dias;
                </li>
                <li>
                  Criar uma página com as informações sobre a última versão da
                  aplicação e as melhorias implementadas;
                </li>
                <li>
                  Disponibilização de todas as redações da escola no perfil do
                  Diretor(a) Geral, Diretor(a) Auxiliar e Pedagogos(as).
                </li>
              </ul>
              <br />
            </Typography>
          </div>
        </Container>
      </div>
      <br />
    </>
  );
}
const checkIcon = (
  <svg
    style={{ width: "1.2rem" }}
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={3}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
  </svg>
);

import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";

const BotoesInvalidacao = (props) => {
  const togglePlagio = () => setShowModalPlagio(!showModalPlagio);
  const toggleInvalidacao = () => setShowModalValidacao(!showModalValidacao);
  const [showModalPlagio, setShowModalPlagio] = useState(false);
  const [showModalValidacao, setShowModalValidacao] = useState(false);

  const isCorrigidoIA = props.correcaoIA;
  const isPlagio = props.plagio?.plagio;
  const isValida = props.validacao?.validacao;
  return (
    <>
      <center>
        {isPlagio && (
          <Button
            variant="contained"
            color="error"
            sx={{ mr: 2 }}
            onClick={togglePlagio}
          >
            POSSÍVEL PLÁGIO
          </Button>
        )}
        {!isValida && (
          <>
          <Button variant="contained" color="error" onClick={toggleInvalidacao}>
            POSSÍVEL INVALIDAÇÃO
          </Button>
          </>
        )}
        {!isCorrigidoIA && (
          <>
            <br />
            <p className="mb-5">
              <b>
                Aguardando a correção feita pela inteligência artificial GEMINI
              </b>
            </p>
          </>
        )}
      </center>
      <br />
      <hr />
      <br />

      <Modal
        open={showModalPlagio}
        onClose={() => setShowModalPlagio(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            POSSÍVEL PLÁGIO DETECTADO
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {props.plagio?.justificativa || "Justificativa não disponível"}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowModalPlagio(false)}
            sx={{ mt: 3, mr: 1 }}
          >
            Fechar
          </Button>
        </Box>
      </Modal>

      <Modal
        open={showModalValidacao}
        onClose={() => setShowModalValidacao(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            POSSÍVEL INVALIDAÇÃO DETECTADA
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {props.validacao?.justificativa || "Justificativa não disponível"}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowModalValidacao(false)}
            sx={{ mt: 3, mr: 1 }}
          >
            Fechar
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default BotoesInvalidacao;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const ErrorList = (props) => {
	const classes = useStyles();
	const [load, setLoad] = useState();

	const [sSintaxe, setsSintaxe] = useState(0);
	const [sAcentuacao, setsAcentuacao] = useState(0);
	const [sOrtografia, setsOrtografia] = useState(0);
	const [sSemantica, setsSemantica] = useState(0);
	const [sOutras, setsOutras] = useState(0);

	var count = 0;
	var Sintaxe = 0;
	var Acentuacao = 0;
	var Ortografia = 0;
	var Semantica = 0;
	var Outras = 0;

	useEffect(() => {
		HandleJson(props.json);

		function HandleJson(json) {
			var object = JSON.parse(JSON.stringify(json.matches));
			object.map((item) => {
				count = count + 1;

				switch (item.rule.category.name) {
					case "Acentuação":
						Acentuacao = Acentuacao + 1;
						break;
					case "Regras de Marcas e Termos Registados":
						Outras = Outras + 1;
						break;
					case "Palavras Normalmente Confusas":
						Acentuacao = Acentuacao + 1;
						break;
					case "Calinadas":
						Ortografia = Ortografia + 1;

						break;
					case "Capitalização":
						Ortografia = Ortografia + 1;
						break;
					case "Composição":
						Ortografia = Ortografia + 1;
						break;
					case "Erro de Escrita":
						Ortografia = Ortografia + 1;
						break;
					case "Erros Ortográficos":
						Ortografia = Ortografia + 1;
						break;
					case "Maiúsculo / Minúsculo":
						Ortografia = Ortografia + 1;
						break;
					case "Palavras Compostas":
						Ortografia = Ortografia + 1;
						break;
					case "Regras Específicas da Wikipédia":
						Ortografia = Ortografia + 1;
						break;
					case "Sem Categoria Definida":
						Ortografia = Ortografia + 1;
						break;
					case "Style":
						Ortografia = Ortografia + 1;
						break;
					case "Contrações":
						Outras = Outras + 1;
						break;
					case "Desenvolvimento":
						Outras = Outras + 1;
						break;
					case "Linguagem Formal":
						Outras = Outras + 1;
						break;
					case "Redundância":
						Outras = Outras + 1;
						break;
					case "Regionalismos":
						Outras = Outras + 1;
						break;
					case "Tipografia":
						Outras = Outras + 1;
						break;
					case "Confusão de Palavras":
						Semantica = Semantica + 1;
						break;
					case "Dicas de estilo para uma escrita criativa":
						Semantica = Semantica + 1;
						break;
					case "Erros de Tradução":
						Semantica = Semantica + 1;
						break;
					case "Escrita Criativa":
						Semantica = Semantica + 1;
						break;
					case "Frases - Feitas e Expressões Idiomáticas":
						Semantica = Semantica + 1;
						break;
					case "Regras de Marcas e Termos Registrados":
						Semantica = Semantica + 1;
						break;
					case "Semântica":
						Semantica = Semantica + 1;
						break;
					case "Análise de texto":
						Sintaxe = Sintaxe + 1;
						break;
					case "Dicas de redação":
						Sintaxe = Sintaxe + 1;
						break;
					case "Estilo":
						Sintaxe = Sintaxe + 1;
						break;
					case "Gramática Geral":
						Sintaxe = Sintaxe + 1;
						break;
					case "Pontuação":
						Sintaxe = Sintaxe + 1;
						break;
					case "Repetições":
						Sintaxe = Sintaxe + 1;
						break;
					case "Repetições(estilo)":
						Sintaxe = Sintaxe + 1;
						break;
					case "Sintaxe":
						Sintaxe = Sintaxe + 1;
						break;
					default:
						console.error(
							"Categoria não encontrada: ",
							item.rule.category.name
						);
						break;
				}

				return item;
			});
		}
		setsAcentuacao(Acentuacao);
		setsOrtografia(Ortografia);
		setsSemantica(Semantica);
		setsSintaxe(Sintaxe);
		setsOutras(Outras);
		setLoad(true);
		console.log("Acentuação:" + Acentuacao + "/ Ortografia:" + Ortografia)
	}, []);

	if (load) {
		return (
			<>
				<Typography style={{ textAlign: "center", textTransform:"uppercase", color:"var(--blue-light)" }} variant={"h6"}>
					Erros encontrados
				</Typography>

				<div className={classes.container}>
					{/* <div
						className={classes.box}
						style={{ backgroundColor: "#ffe0ad", textAlign: "center" }}
					>
						<Typography>Acentuação {sAcentuacao} </Typography>
					</div>
					<div
						className={classes.box}
						style={{ backgroundColor: "#cef2d5", textAlign: "center" }}
					>
						<Typography>Ortografia {sOrtografia}</Typography>
					</div> */}
					<div
						className={classes.box}
						style={{ backgroundColor: "#cef2d5", textAlign: "center", alignItems: "center", borderRadius: "0.25rem" }}
					>
						<Typography>ORTOGRAFIA: {sOrtografia + sAcentuacao}</Typography>
					</div>
					<div
						className={classes.box}
						style={{ backgroundColor: "#e1b6fc", textAlign: "center", alignItems: "center", borderRadius: "0.25rem" }}
					>
						<Typography>SEMÂNTICA: {sSemantica}</Typography>
					</div>
					<div
						className={classes.box}
						style={{ backgroundColor: "#ed9332", textAlign: "center", alignItems: "center", borderRadius: "0.25rem" }}
					>
						<Typography>SINTAXE: {sSintaxe}</Typography>
					</div>
					<div
						className={classes.box}
						style={{ backgroundColor: "#b4bbfa", textAlign: "center", alignItems: "center", borderRadius: "0.25rem" }}
					>
						<Typography>OUTROS: {sOutras}</Typography>
					</div>
				</div>
			</>
		);
	} else {
		return <div>Carregando...</div>;
	}
};
export default ErrorList;

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		marginTop: 20,
		width: "100%",
		margin: "20px auto",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
		},
	},
	box: {
		margin: "auto",
		height: "50px",
		justifyContent: "center",
		borderRadius: 10,
		display: "flex",
		flexDirection: "row",
		width: 150,
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
			width: "90%",
			marginTop: 10,
		},
	},
}));

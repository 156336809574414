import styled from 'styled-components'

interface Props { 
    BorderBottom: string; 
}


export const Container = styled.div<Props>`
margin: 15px;
padding: 20px;
background: var(--white);
border-radius: 0.25rem;
border-width: 0.15rem;
border-style: solid;
border-color: var(--background);
width: 47%;
cursor: pointer;
border-bottom-color: ${props => props.BorderBottom};

@media(max-width: 900px){
    width: 100%;
}

&:hover{
    filter: brightness(0.95);
    }

`
import React, { useReducer } from "react";
import Routes from "./routes";
import { Provider } from "./store";
import { authReducer, initialAuthState } from "./reducers/authReducer";

import CssBaseline from "@material-ui/core/CssBaseline";
import { GlobalStyle } from "./styles/global";

import packageJson from "../package.json";
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);
function App() {
  console.log("Cache cleaned");
  return <ClearCacheComponent />;
}
function MainApp() {
  const useAuthState = useReducer(authReducer, initialAuthState);
  return (
    <html lang="pt-BR">
      <GlobalStyle />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />

      <Provider value={useAuthState}>
        <Routes />
      </Provider>
    </html>
  );
}

export default App;

export default function Logout() {
	localStorage.removeItem("Token");
	localStorage.removeItem("RefreshToken");
	removeCelepar();

	function removeCelepar() {
		Redirect();
	}
	return <></>;
}

function Redirect() {
	localStorage.clear();
	return (window.location = "/login");
}

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { isAuthenticated, getProfileId } from "./domain/auth/AuthService";
import { Login } from "./pages/Login";
import { Home } from "../src/pages/Home";

import StudentsHome from "../src/pages/Student/StudentsHome";
import StudentEssay from "../src/pages/Student/Essay";
import NewEssay from "../src/pages/Student/NewEssay";
import CorrectionRules from "../src/pages/Student/CorrectionRules";
import SearchByToken from "../src/pages/Student/SearchByToken";

import AdministratorHome from "../src/pages/Administrator/AdministratorHome";
import Theme from "../src/pages/Administrator/Theme";
import NewTheme from "../src/pages/Administrator/NewTheme";

import Info from "../src/pages/About/Info/index";

import TeacherHome from "../src/pages/Teacher/TeacherHome";
import Diagnostic from "../src/pages/Teacher/Diagnostic/Diagnostic";
import PracticeEssay from "../src/pages/Teacher/PracticeEssay";
import TeacherEssay from "../src/pages/Teacher/Essay";
import TeacherNewTheme from "../src/pages/Teacher/NewTheme";
import TeacherUpdateTheme from "../src/pages/Teacher/UpdateTheme";
import { Class } from "../src/pages/Teacher/Class";
import { ClassroomItem } from "../src/pages/Teacher/StudentsList";
import { CheckToken } from "./component/redirects/CheckEssayToken";
import { uuid } from "./utils/utils";

import Logout from "./domain/auth/Logout";
import TeacherQuill from "pages/Teacher/TeacherQuill/quill";
export function getAuthed() {
  var authed = isAuthenticated();
  if (authed === false) {
    return 0;
  }
  return getProfileId();
}
export default function Routes() {
  return (
    <section id="main">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />

          <Route path="/essay/students/:token" component={CheckToken} />

          <PrivateRoute
            exact
            path="/student/home"
            component={StudentsHome}
            authed={getAuthed() === 8 || getAuthed() === 11}
          />

          <PrivateRoute
            path="/about/info"
            component={Info}
            authed={getAuthed() === 8 || getAuthed() === 10}
          />

          <PrivateRoute
            path="/student/essay/new"
            component={NewEssay}
            authed={getAuthed() === 8 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/student/essay/:token"
            component={StudentEssay}
            authed={getAuthed() === 8 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/student/rules"
            component={StudentsHome}
            authed={getAuthed() === 8 || getAuthed() === 11}
          />

          <PrivateRoute
            path="/student/CorrectionRules"
            component={CorrectionRules}
            authed={getAuthed() === 8 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/student/SearchByToken"
            component={SearchByToken}
            authed={getAuthed() === 8 || getAuthed() === 11}
          />

          <PrivateRoute
            path="/teacher/home"
            component={TeacherHome}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/Teacher/Diagnostic/Diagnostic"
            component={Diagnostic}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/quill"
            component={TeacherQuill}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/essay/new"
            component={TeacherNewTheme}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/class/:token"
            component={Class}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/class/:classroomId/student/:tokenStudent"
            component={ClassroomItem}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/PracticeEssay"
            component={PracticeEssay}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/essay/all/student"
            component={TeacherHome}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/pratice/essay/:token"
            component={StudentEssay}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/reports"
            component={TeacherHome}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/essay/correction/:token"
            component={TeacherEssay}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/teacher/essay/:token"
            component={TeacherUpdateTheme}
            authed={getAuthed() === 10 || getAuthed() === 11}
          />
          <PrivateRoute
            path="/administrator/home"
            component={AdministratorHome}
            authed={getAuthed() === 11}
          />
          <PrivateRoute
            path="/administrator/essay/new"
            component={NewTheme}
            authed={getAuthed() === 11}
          />
          <PrivateRoute
            path="/administrator/theme/all"
            component={AdministratorHome}
            authed={getAuthed() === 11}
          />
          <PrivateRoute
            path="/administrator/theme/id"
            component={Theme}
            authed={getAuthed() === 11}
          />
          <PrivateRoute
            path="/administrator/theme/all"
            component={AdministratorHome}
            authed={getAuthed() === 11}
          />

          <Redirect to="/" />
        </Switch>
      </Router>
    </section>
  );
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} key={uuid()} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

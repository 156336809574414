import React, { useEffect } from "react";
import { apiSecure } from "domain/api";

export function GetReviewByToken(props) {
  useEffect(() => {
    if (props !== undefined && props.token !== undefined) {
      apiSecure
        .get("/Review/" + props.token)
        .then(function (response) {
          props.callback(response.data);
        })
        .catch(function (error) {});
    }
  }, []);
  return <></>;
}

import React, { useEffect, useState } from "react";
import { Topbar } from "component/Topbar";
import {
  Container,
  Row,
  RowWrapper,
  Col1,
  Col3,
  Col4,
  Col6,
  Col12,
  Col3Competence,
  CardGraph,
  Gap,
  CardUpper,
  CardBottom,
  Card,
  CardQtdAlunos,
} from "./styles";
import GaugeChart from "component/chart/GaugeChart";
import ColumnChartWithGroupLabel from "component/chart/DistributedColumnsChart";
import BarWithMarkersChart from "component/chart/BarWithMarkersChart";
import { apiDiagnostic } from "domain/api";
import InfoNoteNoURL from "component/InfoNoteNoURL";

interface getEscolaSerie {
  cod_Serie: number;
  desc_Serie: string;
  escola_Serie: string;
}
interface getTurma {
  cod_Turma: number;
  turma: string;
}
interface getTema {
  essayId: number;
  cod_Tema: number;
  tema: string;
  createdAt: string;
  createdBy: string;
}
interface getMetricas {
  createdAt: Date;
  createdBy: number;
  qtdAlunos: number;
  pendentes: number;
  emRascunho: number;
  enviadasCorrecao: number;
  devolvidasReescrita: number;
  concluidas: number;
  qtdRedacoes: number;
  emCorrecaoOnline: number;
}
interface getMedias {
  createdAt: Date;
  createdBy: number;
  media: number;
}
interface getCompetencias {
  cod_Turma: number;
  turma: string;
  descriptor: number;
  competencia: string;
  desc_Competencia: string;
  media_Competencia: number;
  max_Score: number;
}
interface CompetenciaItem {
  desc_turma: string;
  media: number;
}

interface CompetenciaChart {
  tituloCompetencia: string;
  turmas: CompetenciaItem[];
  max_Score: number;
  desc_Competencia: string;
}

interface TurmaForChart {
  nome: string;
  pontuacao: number;
  goals: Array<{ name: string; value: number }>;
}

function getDayMinusOneFormatted(): string {
  const today = new Date();
  const dayMinusOne = new Date(today);
  dayMinusOne.setDate(today.getDate() - 1);

  const day = dayMinusOne.getDate().toString().padStart(2, "0");
  const month = (dayMinusOne.getMonth() + 1).toString().padStart(2, "0");
  const year = dayMinusOne.getFullYear();

  return `${day}/${month}/${year}`;
}

export default function Diagnostic() {
  //#region CONSTANTES
  const [escolaSerie, setEscolaSerie] = useState<getEscolaSerie[]>([]);
  const [turma, setTurma] = useState<getTurma[]>([]);
  const [tema, setTema] = useState<getTema[]>([]);
  const [competencias, setCompetencias] = useState<getCompetencias[]>([]);
  const [selecionado, setSelecionado] = useState("");
  const [turmaSelecionada, setTurmaSelecionada] = useState("");
  const [nameTheme, setNameTheme] = useState("");
  const [codTema, setCodTema] = useState<number>(0);
  const [codTurma, setCodTurma] = useState<number>(0);
  const [terceiroSelecionado, setTerceiroSelecionado] = useState("");
  const [essayId, setEssayId] = useState<number>(0);
  const dayMinusOne = getDayMinusOneFormatted();
  const [showQtdAlunos, setShowQtdAlunos] = useState(false);
  const [competenciaArray, setCompetenciaArray] = useState<CompetenciaChart[]>(
    []
  );
  const [medias, setMedias] = useState<getMedias>({
    createdAt: new Date(),
    createdBy: 0,
    media: 0,
  });
  const [metricas, setMetricas] = useState<getMetricas>({
    createdAt: new Date(),
    createdBy: 0,
    qtdAlunos: 0,
    pendentes: 0,
    emRascunho: 0,
    enviadasCorrecao: 0,
    devolvidasReescrita: 0,
    concluidas: 0,
    qtdRedacoes: 0,
    emCorrecaoOnline: 0,
  });
  const [
    segundoSelectHabilitado,
    setSegundoSelectHabilitado,
  ] = useState<boolean>(false);
  const [
    terceiroSelectHabilitado,
    setTerceiroSelectHabilitado,
  ] = useState<boolean>(false);
  //#endregion CONSTANTES

  //#region BUSCA
  //#region BUSCA ESCOLA/SERIE
  useEffect(() => {
    setNameTheme("Selecione uma opção");
    const nomeUsuario = localStorage.getItem("Name");
    const baseURL = process.env.REACT_APP_DIAGNOSTIC_API_URL;

    if (nomeUsuario) {
      apiDiagnostic
        .get(baseURL + "Filtros/series?name=" + nomeUsuario)
        .then((response) => {
          setEscolaSerie(response.data as getEscolaSerie[]);
        })
        .catch((error) => {
          //console.error("Houve um erro na chamada da API:", error);
        });
    }
  }, []);
  //#endregion BUSCA ESCOLA/SERIE
  //#region BUSCA TURMAS
  const buscarTurmas = async (escolaSerie: string) => {
    const nomeUsuario = localStorage.getItem("Name");
    const baseURL = process.env.REACT_APP_DIAGNOSTIC_API_URL;
    if (nomeUsuario) {
      apiDiagnostic
        .get(
          baseURL +
            "Filtros/turmas?name=" +
            nomeUsuario +
            "&escola_serie=" +
            escolaSerie
        )
        .then((response) => {
          setTurma(response.data as getTurma[]);
        })
        .catch((error) => {
          //console.error("Houve um erro na chamada da API:", error);
        });
    }
  };
  //#endregion BUSCA TURMAS
  //#region BUSCA TEMAS
  const buscarTema = async (classroomId: number) => {
    const nomeUsuario = localStorage.getItem("Name");
    const baseURL = process.env.REACT_APP_DIAGNOSTIC_API_URL;
    if (nomeUsuario) {
      apiDiagnostic
        .get(
          baseURL +
            "Filtros/temas?name=" +
            nomeUsuario +
            "&classroomId=" +
            classroomId
        )
        .then((response) => {
          const dadosApi = response.data[0];
          setTema(response.data as getTema[]);
          setEssayId(dadosApi.essayId);
          if (response.data[0].tema === "Nenhum tema encontrado") {
            setTerceiroSelectHabilitado(false);
            setNameTheme("Nenhum tema encontrado");
          } else {
            setNameTheme("Selecione uma opção");
          }
        })
        .catch((error) => {
          //console.error("Houve um erro na chamada da API:", error);
        });
    }
  };
  //#endregion BUSCA TEMAS
  //#region BUSCA METRICAS
  const buscarMetricas = async (tema: string, cod_Turma: number) => {
    const nomeUsuario = localStorage.getItem("Name");
    const baseURL = process.env.REACT_APP_DIAGNOSTIC_API_URL;
    if (nomeUsuario) {
      apiDiagnostic
        .get(
          baseURL +
            "Analytics/metricas?name=" +
            nomeUsuario +
            "&tema=" +
            tema +
            "&classroomId=" +
            cod_Turma
        )
        .then((response) => {
          const dadosApi = response.data[0];
          if (!dadosApi) {
            //console.error("Nenhum dado retornado pela API");
            return;
          }
          setMetricas({
            createdAt: dadosApi.createdAt
              ? new Date(dadosApi.createdAt)
              : new Date(),
            createdBy: dadosApi.createdBy || 0,
            qtdAlunos: dadosApi.qtdAlunos || 0,
            pendentes: dadosApi.pendentes || 0,
            emRascunho: dadosApi.emRascunho || 0,
            enviadasCorrecao: dadosApi.enviadasCorrecao || 0,
            devolvidasReescrita: dadosApi.devolvidasReescrita || 0,
            concluidas: dadosApi.concluidas || 0,
            qtdRedacoes: dadosApi.qtdRedacoes || 0,
            emCorrecaoOnline: dadosApi.emCorrecaoOnline || 0,
          });
          setShowQtdAlunos(true);
        })

        .catch((error) => {
          //console.error("Houve um erro na chamada da API:", error);
        });
    }
  };
  //#endregion BUSCA METRICAS
  //#region BUSCAR MEDIA DAS NOTAS
  const buscarMedias = async (tema: string, cod_Turma: number) => {
    const nomeUsuario = localStorage.getItem("Name");
    const baseURL = process.env.REACT_APP_DIAGNOSTIC_API_URL;
    if (nomeUsuario) {
      apiDiagnostic
        .get(
          baseURL +
            "Analytics/medias?name=" +
            nomeUsuario +
            "&tema=" +
            tema +
            "&classroomId=" +
            cod_Turma
        )
        .then((response) => {
          const dadosApi = response.data[0];
          setMedias({
            media: dadosApi.media || 0,
            createdAt: dadosApi.createdAt
              ? new Date(dadosApi.createdAt)
              : new Date(),
            createdBy: dadosApi.createdBy,
          });
        })

        .catch((error) => {
          //console.error("Houve um erro na chamada da API:", error);
        });
    }
  };
  //#endregion BUSCAR MEDIA DAS NOTAS
  //#region BUSCA COMPETENCIAS
  const buscarCompetencias = async (escolaSerie: string, codTema: number) => {
    //console.log(escolaSerie," - ", codTema);
    const nomeUsuario = localStorage.getItem("Name");
    const baseURL = process.env.REACT_APP_DIAGNOSTIC_API_URL;
    if (nomeUsuario) {
      apiDiagnostic
        .get(
          baseURL +
            "Analytics/competencias?name=" +
            nomeUsuario +
            "&escola_Serie=" +
            escolaSerie +
            "&tema=" +
            codTema
        )
        .then((response) => {
          //console.log("Dados da API (Competências):", response.data);
          const dadosApi: getCompetencias[] = response.data;
          setCompetencias(dadosApi);
          transformarDados(dadosApi);
        })
        .catch((error) => {
          //console.error("Houve um erro na chamada da API:", error);
        });
    }
  };
  //#endregion BUSCA COMPETENCIAS
  //#endregion BUSCA

  const transformarDados = (dadosApi: getCompetencias[]) => {
    console.log("Dados recebidos da API:", dadosApi);
    const competenciasAgrupadas: { [key: number]: CompetenciaChart } = {};

    dadosApi.forEach((competencia) => {
      const descriptorKey = competencia.descriptor;

      if (!competenciasAgrupadas[descriptorKey]) {
        competenciasAgrupadas[descriptorKey] = {
          desc_Competencia: competencia.desc_Competencia,
          tituloCompetencia: competencia.competencia,
          turmas: [],
          max_Score: competencia.max_Score,
        };
      }

      competenciasAgrupadas[descriptorKey].turmas.push({
        desc_turma: competencia.turma,
        media: competencia.media_Competencia,
      });
    });

    const competenciasArray = Object.values(competenciasAgrupadas);
    //console.log(competenciasArray);
    setCompetenciaArray(competenciasArray);
  };

  const handlePrimeiroSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const valor = e.target.value;
    //console.log(valor);

    setSelecionado(valor);
    setTurmaSelecionada("");
    setTerceiroSelecionado("");

    if (valor !== "0") {
      buscarTurmas(valor);
      setSegundoSelectHabilitado(true);
      setTerceiroSelectHabilitado(false);
    } else {
      setSegundoSelectHabilitado(false);
    }
  };

  const handleSegundoSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const valorSelecionado = e.target.value;
    const [codTurmaString, nomeTurma] = e.target.value.split("|");
    const codTurma = parseInt(codTurmaString, 10);

    setTurmaSelecionada(valorSelecionado);
    setCodTurma(codTurma);
    setTerceiroSelecionado("");

    if (codTurma !== 0) {
      buscarTema(codTurma);
      setTerceiroSelectHabilitado(true);
    } else {
      setTerceiroSelectHabilitado(false);
    }
  };

  const handleTerceiroSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const [codTemaString, nomeTema] = e.target.value.split("|");
    //console.log(nomeTema, " | ", codTemaString);
    const codTema = parseInt(codTemaString, 10);
    setTerceiroSelecionado(`${codTemaString}|${nomeTema}`);

    if (codTema !== 0) {
      setCodTema(codTema);
      buscarMetricas(nomeTema, codTurma);
      buscarMedias(nomeTema, codTurma);
      buscarCompetencias(selecionado, codTema);
      setTerceiroSelectHabilitado(true);
    } else {
      setTerceiroSelectHabilitado(false);
    }
  };

  useEffect(() => {
    //console.log("competencias", competenciaArray);
  }, [competenciaArray]);

  return (
    <>
      <Topbar profile="10" menu="home"></Topbar>
      <div
        style={{
          backgroundColor: "var(--blue-light)",
          margin: "1% 1% 0 1%",
          padding: "0.5%",
          borderTopLeftRadius: "0.25rem",
          borderTopRightRadius: "0.25rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 className="titles" style={{ color: "var(--white)" }}>
          AVALIAÇÃO DIAGNÓSTICA
        </h2>
      </div>
      <Container>
        <Row>
          <RowWrapper>
            <Col1>
              <p>ESCOLA / SÉRIE</p>
            </Col1>
            <Col3>
              <select
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                onChange={handlePrimeiroSelectChange}
                value={selecionado}
              >
                <option value="" disabled selected>
                  Selecione uma opção
                </option>
                {escolaSerie.map((item, index) => (
                  <option key={index} value={item.escola_Serie}>
                    {item.escola_Serie}
                  </option>
                ))}
              </select>
            </Col3>
            <Gap></Gap>
            <Col1>
              <p>TURMA</p>
            </Col1>
            <Col3>
              <select
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                disabled={!segundoSelectHabilitado}
                value={turmaSelecionada}
                onChange={handleSegundoSelectChange}
              >
                <option value="" disabled selected>
                  Selecione uma opção
                </option>{" "}
                {turma.map((item, index) => (
                  <option key={index} value={`${item.cod_Turma}|${item.turma}`}>
                    {item.turma}
                  </option>
                ))}
              </select>
            </Col3>
            <Gap></Gap>
            <Col1>
              <p>TEMA</p>
            </Col1>
            <Col3>
              <select
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                disabled={!terceiroSelectHabilitado}
                onChange={handleTerceiroSelectChange}
                value={terceiroSelecionado}
              >
                <option value="" selected disabled>
                  {nameTheme}
                </option>
                {tema.map((item, index) => (
                  <option key={index} value={`${item.cod_Tema}|${item.tema}`}>
                    {item.tema}
                  </option>
                ))}
              </select>
            </Col3>
          </RowWrapper>
        </Row>
        <Col12>
          ATENÇÃO: OS DADOS APRESENTADOS NESTA TELA ESTÃO ATUALIZADOS ATÉ {dayMinusOne}
        </Col12>
        <Row>
          <RowWrapper>
            <Card>
              <CardUpper>QUANTIDADE DE ALUNOS</CardUpper>
              <CardBottom>
                <CardQtdAlunos show={showQtdAlunos} key={metricas.qtdAlunos}>
                  {metricas.qtdAlunos ?? 0}
                </CardQtdAlunos>
              </CardBottom>
            </Card>
            <Gap></Gap>
            <Card>
              <CardUpper>REDAÇÕES</CardUpper>
              <CardBottom>
                <ColumnChartWithGroupLabel
                  pendentes={metricas.pendentes ?? 0}
                  emRascunho={metricas.emRascunho ?? 0}
                  emCorrecaoOnline={metricas.emCorrecaoOnline ?? 0}
                  enviadasCorrecao={metricas.enviadasCorrecao ?? 0}
                  devolvidasReescrita={metricas.devolvidasReescrita ?? 0}
                  concluidas={metricas.concluidas ?? 0}
                  qtdRedacoes={metricas.qtdRedacoes ?? 0}
                />
              </CardBottom>
            </Card>
            <Gap></Gap>
            <Card>
              <CardUpper>MÉDIA DAS NOTAS</CardUpper>
              <CardBottom>
                {metricas.concluidas > 0 ? (
                  <GaugeChart mediaNotas={medias.media ?? 0} />
                ) : (
                  <GaugeChart mediaNotas={0} />
                )}
              </CardBottom>
            </Card>
          </RowWrapper>
        </Row>
      </Container>
      {terceiroSelecionado && (
        <>
          <div
            style={{
              backgroundColor: "var(--blue-light)",
              margin: "1% 1% 0 1%",
              padding: "0.5%",
              borderTopLeftRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 className="titles" style={{ color: "var(--white)" }}>
              MÉDIA DE COMPETÊNCIAS
            </h2>
          </div>
          <Container>
            {competenciaArray &&
            competenciaArray.length > 0 &&
            metricas.concluidas > 0 ? (
              competenciaArray.map((competencia, index) => (
                <React.Fragment key={index}>
                  <Card>
                    <InfoNoteNoURL tooltipText={competencia.desc_Competencia} />

                    {competencia.turmas && competencia.turmas.length > 0 ? (
                      <>
                        <BarWithMarkersChart
                          turmas={competencia.turmas.map(
                            (turma): TurmaForChart => ({
                              nome: turma.desc_turma,
                              pontuacao: turma.media,
                              goals: [
                                {
                                  name: "Meta",
                                  value: competencia.max_Score,
                                },
                              ],
                            })
                          )}
                          tituloGrafico={competencia.tituloCompetencia}
                          max_Score={competencia.max_Score}
                        />
                      </>
                    ) : (
                      <div>Carregando dados...</div>
                    )}
                  </Card>
                  <Gap />
                </React.Fragment>
              ))
            ) : (
              <div>Nenhuma redação corrigida.</div>
            )}
          </Container>
        </>
      )}
    </>
  );
}

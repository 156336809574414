import React, { useEffect, useState, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Topbar } from "component/Topbar";
import Divider from "@material-ui/core/Divider";
import { languageApi, apiSecure } from "domain/api";
import { Accordion, AccordionItem } from "react-sanfona";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
  Hidden,
} from "@material-ui/core";
import { Box, Modal, Rating } from "@mui/material";
import Correction from "./component/Correction";
import ErrorList from "./component/ErrorList";
import qs from "querystring";
import CorrectionHistory from "./component/CorrectionHistory";
import DialogActions from "@material-ui/core/DialogActions";
import { GetDetails as GetEssayDetails } from "domain/essayTeacher/service";
import {
  GetDetails as GetThemeDetails,
  GetGenre,
  GetType,
  GetMotivatingText,
} from "domain/theme/service";
import { useHistory } from "react-router-dom";
import Descriptor2 from "./component/Descriptor2";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import PlagiarismResult from "./component/PlagiarismResult";
import { GetReviewByToken } from "domain/review/service";
import { BorderTopRounded, Tune } from "@material-ui/icons";
import loadingLogo from "../../../component/images/loader3.gif";
import { useParams } from "react-router-dom";
import BotoesInvalidacao from "./component/botoesInvalidacao/botoesInvalidacao";
import { cp } from "fs";

export default function Essay(props) {
  const [set, setset] = useState([]);
  const { token } = useParams();
  const [detalhes, setDetalhes] = useState("");
  const [correcaoIA, setCorrecaoIA] = useState("");
  const classes = useStyles();
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [devolutive, setDevolutive] = useState("");
  const [openTeacherNote, setOpenTeacherNote] = useState(false);
  const [essayDetails, setEssayDetails] = useState();
  const [json, setJson] = useState({});
  const [finalScore, setFinalScore] = useState(0);
  const [run, setRun] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [sendDevolutive, setSendDevolutive] = useState(false);
  const [open, toggleOpen] = useState(false);
  const [theme, setTheme] = useState({});
  const [genre, setGenre] = useState();
  const [type, setType] = useState();
  const [motivatingTexts, setMotivatingTexts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDescriptors, setOpenDescriptors] = useState(false);
  const [remake, setRemake] = useState(false);
  const [finish, setFinish] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [descriptores, setDescriptors] = useState([]);
  const [descriptoresProf, setDescriptoresProf] = useState([]);
  const [Id, setId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef();
  const [reviewDetails, setReviewDetails] = useState();
  const [readOnly, setReadOnly] = useState(localStorage.getItem("IsReadOnly"));
  const [rating, setRating] = React.useState(5);
  const [plagio, setPlagio] = useState([]);
  const [validacao, setValidacao] = useState([]);
  const [notaPlataforma, setNotaPlataforma] = useState();
  const [notaProfessor, setnotaProfessor] = useState();
  const [comparaDetalhes, setComparaDetalhes] = useState("");
  const [competencia2Alterada, setCompetencia2Alterada] = useState(false);
  const [competencia3Alterada, setCompetencia3Alterada] = useState(false);
  const [competencia4Alterada, setCompetencia4Alterada] = useState(false);
  const [competencia5Alterada, setCompetencia5Alterada] = useState(false);
  const [devolutivaAlterada, setDevolutivaAlterada] = useState(false);
  const [showModalPlagio, setShowModalPlagio] = useState(false);
  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const [comment, setComment] = useState("");
  const [readyToSend, setReadyToSend] = useState(false);
  const [notaNaoAtribuida, SetNotaNaoAtribuida] = useState(true);
  const [hasNotaProf, setHasNotaProf] = useState(false);
  const [paramsData, SetParamsData] = useState("");

  let history = useHistory();
  useEffect(() => {
    const todasPreenchidas =
      comparaDetalhes.competencia2Nota != null &&
      comparaDetalhes.competencia3Nota != null &&
      comparaDetalhes.competencia4Nota != null &&
      comparaDetalhes.competencia5Nota != null;

    SetNotaNaoAtribuida(!todasPreenchidas);
  }, [
    comparaDetalhes.competencia2Nota,
    comparaDetalhes.competencia3Nota,
    comparaDetalhes.competencia4Nota,
    comparaDetalhes.competencia5Nota,
  ]);
  function changeDevolutiva() {
    setDevolutivaAlterada(true);
  }

  function handleType(props) {
    const result = props.find((item) => item.id === theme.themeTypeId);
    setType(result.description);
  }

  function handleGenre(props) {
    const result = props.find((item) => item.id === theme.themeGenreId);
    setGenre(result.description);
  }

  useEffect(() => {
    if (remake && !finish > 0) {
      setOpenDialog(true);
    }
    if (finish && !remake) {
      setOpenDescriptors(true);
    }
  }, [remake, finish]);

  useEffect(() => {}, [finalScore]);
  useEffect(() => {
    if (essayDetails && essayDetails.text.length > 0) {
      setText(essayDetails.text);
      setTitle(essayDetails.title);
      if (
        essayDetails.languageToolObj === undefined ||
        essayDetails.languageToolObj === null
      ) {
        setRun(true);
      } else {
        setJson(essayDetails.languageToolObj);
        toggleOpen(true);
      }

      if (essayDetails.status !== 4) {
        setDisabled(true);
      }
    }
  }, [essayDetails]);

  useEffect(() => {
    setWordCount(0);
    if (text.length > 0) {
      setWordCount(
        text.trim().split(/\b[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{1,50}\b/)
          .length - 1
      );
    }
  }, [text]);

  function GetDescriptors() {
    apiSecure
      .get(
        "/Descriptor/type/3" +
          //theme.themeTypeId +
          "/genre/1" +
          //theme.themeGenreId +
          "/token/" +
          token
      )
      .then((response) => {
        setDescriptors(response.data.descritores);
        SetParamsData(response.data.data);
        if (response.data.correcaoIA) {
          setDetalhes(response.data.detalhamento || {});
          setComparaDetalhes(response.data.detalhamento || {});
          if (response.data.detalhamento.justificativaFinal) {
            setDevolutive(response.data.detalhamento.justificativaFinal);
          }
        }
        if (response.data.data.notaProf) {
          setHasNotaProf(true);
          setDescriptoresProf(response.data.data || {});
          setDevolutive(response.data.data.devolutivaProf);
        }
        setCorrecaoIA(response.data.correcaoIA);
        setPlagio(response.data.plagio);
        setValidacao(response.data.validacao);
        //console.log("validacao ", response.data.validacao);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (descriptores && reviewDetails) {
      let mydesc = [];
      let allIds = new Set();

      // Mapeamento de reviewDetails para `mydesc` e coleta de ids
      reviewDetails.result.forEach((r) => {
        let matchedDescriptor = descriptores.find(
          (e) => e.category === r.category && e.id === r.id
        );
        if (matchedDescriptor) {
          mydesc.push({
            category: matchedDescriptor.category,
            score: r.score,
            maxScore: matchedDescriptor.maxScore,
            options: matchedDescriptor.options,
            typeId: matchedDescriptor.typeId,
            description: matchedDescriptor.description,
            id: matchedDescriptor.id,
          });
          allIds.add(matchedDescriptor.id);
        }
      });

      // Adiciona descritores que não estão em reviewDetails
      descriptores.forEach((e) => {
        if (!allIds.has(e.id)) {
          mydesc.push({
            category: e.category,
            score: 0,
            maxScore: e.maxScore,
            options: e.options,
            typeId: e.typeId,
            description: e.description,
            id: e.id,
          });
        }
      });

      // Ordenar por id
      mydesc.sort((a, b) => a.id - b.id);

      setset(mydesc);
    } else {
      setset(descriptores ? descriptores.sort((a, b) => a.id - b.id) : []);
    }
  }, [reviewDetails, descriptores]);

  useEffect(() => {
    var status = "";
    if (sendDevolutive === true) {
      setSendDevolutive(false);
      if (remake) {
        status = "RedoRequired";
      }
      sendReview();
    }

    function sendReview() {
      apiSecure
        .put("/Essay/teacher/" + props.match.params.token + "/review", {
          studentId: props.location.studentId,
          reviewScore: Number(
            essayDetails.reviewScore ? essayDetails.reviewScore : 0
          ),
          reviewText: devolutive,
          status: status,
          score: Number(essayDetails.score).toFixed(0),
        })
        .then((response) => {
          setJson(response.data);
          toggleOpen(true);
          setFinish(false);
          setRemake(false);
          history.push(
            "/teacher/essay/correction/" + props.match.params.token,
            {
              state: { studentId: props.location.studentId },
            }
          );
        })
        .catch((err) => {
          setRun(false);
          setFinish(false);
          setRemake(false);
        });
    }
  }, [sendDevolutive]);

  useEffect(() => {
    if (run === true) {
      setRun(false);
      checkText();
    }

    function checkText() {
      apiSecure
        .post("/Essay/student/v2/check", {
          EssayId: essayDetails?.id,
          Text: text,
          Title: title,
          Status: 4,
          StudentId: essayDetails.studentId,
        })
        .then((response) => {
          setJson(response.data);
          toggleOpen(true);
          setRun(true);
        })
        .catch((err) => {
          setRun(false);
        });
    }
  }, [run]);

  const handleClose = () => {
    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
  };

  const handleDialogConfirm = () => {
    setSendDevolutive(true);
    setOpenDialog(false);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setFinish(false);
    setRemake(false);
  };

  const handleChangeType1 = (value, e) => {
    setId([]);
    if (/[0-9]+$/.test(value.target.value)) console.info("AE");
    else {
      console.error("Nao");
    }
    if (value.target.value > e.maxScore) {
      value.target.value = e.maxScore;
    } else if (value.target.value < 0) {
      value.target.value = 0;
    } else {
      value.target.value = value.target.value;
    }
    var total = 0;
    var array = [];

    descriptores.map((ee) => {
      array.push({
        essayId: essayDetails.id,
        studentId: essayDetails.studentId,
        descriptorId: ee.id,
        descriptorScore: Number(document.getElementById(ee.id).value),
      });
      total = total + Number(document.getElementById(ee.id).value);
    });
    setId(array);

    setEssayDetails(() => ({
      ...essayDetails,
      reviewScore: total,
    }));
  };

  const updateId = useCallback((newObj) => {
    setId((prevId) => {
      const array = prevId.filter(
        (e) => e.descriptorId !== newObj.descriptorId
      );
      return [...array, newObj];
    });
  }, []);

  const handleChangeType2 = (DescriptorId, id, score) => {
    if (score >= 0) {
      const obj = {
        essayId: essayDetails.id,
        studentId: essayDetails.studentId,
        descriptorId: DescriptorId,
        descriptorOptionId: id,
        descriptorScore: score,
      };

      updateId(obj);

      switch (DescriptorId) {
        case 11:
          setComparaDetalhes((prevState) => ({
            ...prevState,
            competencia2Nota: score,
          }));
          break;
        case 12:
          setComparaDetalhes((prevState) => ({
            ...prevState,
            competencia3Nota: score,
          }));
          break;
        case 13:
          setComparaDetalhes((prevState) => ({
            ...prevState,
            competencia4Nota: score,
          }));
          break;
        case 14:
          setComparaDetalhes((prevState) => ({
            ...prevState,
            competencia5Nota: score,
          }));
          break;
        default:
          break;
      }
    }
  };

  function assignGrade() {
    GetDescriptors();
    setFinish(true);
    document.getElementById("dvTheme").style.display = "none";
    document.getElementById("dvTextEvaluation").style.display = "block";
    document.getElementById("btnAssignGrade").style.display = "none";
    document.getElementById("btnRewrite").style.display = "none";
    document.getElementById("btnPopUpProposal").style.display = "block";
    document.getElementById("btnSendGrade").style.display = "block";
    document.getElementById("btnCancelAssignGrade").style.display = "block";
    document.getElementById("correctionHistory").style.display = "none";
  }

  function requestRewrite() {
    document.getElementById("openTeacherNote").style.display = "block";
    document.getElementById("btnAssignGrade").style.display = "none";
    document.getElementById("btnRewrite").style.display = "none";
    document.getElementById("correctionHistory").style.display = "none";
  }

  function cancelAssignGrade() {
    setFinish(false);
    document.getElementById("openTeacherNote").style.display = "none";
    document.getElementById("dvTheme").style.display = "block";
    document.getElementById("dvTextEvaluation").style.display = "none";
    document.getElementById("btnAssignGrade").style.display = "block";
    document.getElementById("btnRewrite").style.display = "block";
    document.getElementById("btnPopUpProposal").style.display = "none";
    document.getElementById("btnSendGrade").style.display = "none";
    document.getElementById("btnCancelAssignGrade").style.display = "none";
    document.getElementById("correctionHistory").style.display = "block";
  }

  function popUpProposal() {
    document.getElementById("dvPopUpProposal").style.display = "block";
  }

  function closePopUpProposal() {
    document.getElementById("dvPopUpProposal").style.display = "none";
  }

  let wMax1 = "70%";
  let wMax2 = "10%";
  let wMax3 = "15%";
  let direction = "row";
  let finalGradeBox = "var(--green)";
  let grade = 0;
  let isMobile = false;
  if (window.innerWidth <= 1280) {
    isMobile = true;
  }

  useEffect(() => {
    if (Id.length > 0) {
      let total = 0;

      Id.map((ee) => {
        total = total + Number(ee.descriptorScore);
      });

      setEssayDetails(() => ({
        ...essayDetails,
        reviewScore: total,
      }));
    }
  }, [Id]);

  const toggleFeedback = () => setShowModalFeedback(!showModalFeedback);
  const togglePlagio = () => setShowModalPlagio(!showModalPlagio);
  const toggleInep = () => {
    const pdfUrl = "src/assets/cartilha-inep.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "cartilha-inep.pdf";
    link.click();
  };

  // Função para somar os valores dos atributos que seguem o padrão "competenciaXNota"
  const somarCompetencias = (obj) => {
    let soma = 0;
    Object.keys(obj).forEach((key) => {
      if (key.startsWith("competencia") && key.endsWith("Nota")) {
        let aux = obj[key];
        soma += Number(aux);
      }
    });

    return soma;
  };

  // const somaNotaAtualizada = Number(notaC2) + Number(notaC3);
  const [somaNotas, setSomaNotas] = useState(0);

  useEffect(() => {
    const novaSoma = somarCompetencias(comparaDetalhes);
    setSomaNotas(novaSoma);
  }, [comparaDetalhes]); // Dependência comparaDetalhes

  const auxnotaProfessor =
    Number(somaNotas) > 0 ? Number(somaNotas).toFixed(0) : 0;

  const auxnotaPlataforma =
    essayDetails && essayDetails.score !== undefined
      ? Number(essayDetails.score)
      : 0;

  const auxnotaFinal = Number(auxnotaProfessor) + auxnotaPlataforma;

  const comparaDetalhesRef = useRef(comparaDetalhes);

  useEffect(() => {
    comparaDetalhesRef.current = comparaDetalhes;
  }, [comparaDetalhes]);

  const competencias = [
    {
      key: "competencia2Nota",
      state: competencia2Alterada,
      setState: setCompetencia2Alterada,
    },
    {
      key: "competencia3Nota",
      state: competencia3Alterada,
      setState: setCompetencia3Alterada,
    },
    {
      key: "competencia4Nota",
      state: competencia4Alterada,
      setState: setCompetencia4Alterada,
    },
    {
      key: "competencia5Nota",
      state: competencia5Alterada,
      setState: setCompetencia5Alterada,
    },
  ];

  function comparadorDeNotas() {
    competencias.forEach(({ key, setState }) => {
      const detalheNota = Number(detalhes[key]);
      const comparaNota = Number(comparaDetalhesRef.current[key]);
      setState(detalheNota !== comparaNota);
    });
  }

  
  const handleEnviarRedacao= () => {
    handleSendScore();
  };

  // useEffect(() => {
  //   console.log("Competencia2Alterada:", competencia2Alterada);
  // }, [competencia2Alterada]);

  // useEffect(() => {
  //   console.log("Competencia3Alterada:", competencia3Alterada);
  // }, [competencia3Alterada]);

  // useEffect(() => {
  //   console.log("Competencia4Alterada:", competencia4Alterada);
  // }, [competencia4Alterada]);

  // useEffect(() => {
  //   console.log("Competencia5Alterada:", competencia5Alterada);
  // }, [competencia5Alterada]);

  const handleSendScore = () => {
    comparadorDeNotas();
    setReadyToSend(true);
  };

  useEffect(() => {
    if (readyToSend) {
      //let notaFeedback = rating * 2;

      const payload = {
        reviewScore: auxnotaProfessor,
        score: auxnotaPlataforma,
        reviewText: devolutive,
        status: 6,
        ReviewDetails: Id,
        redacaoDetalhadaProfessor: {
          idRedacao: String(paramsData.idRedacao),
          token: String(props.match.params.token),
          idEstudante: String(paramsData.idEstudante),
          idProfessor: String(paramsData.professorId),
          idCorrecao: String(paramsData.idCorrecao),

          competencia1Nota: Number(auxnotaPlataforma),
          competencia1Justificativa: null,
          alteradoNota1: false,

          competencia2Nota: Number(comparaDetalhes.competencia2Nota),
          competencia2Justificativa: String(
            comparaDetalhes.competencia2Justificativa
          ),
          alteradoNota2: competencia2Alterada,

          competencia3Nota: Number(comparaDetalhes.competencia3Nota),
          competencia3Justificativa: String(
            comparaDetalhes.competencia3Justificativa
          ),
          alteradoNota3: competencia3Alterada,

          competencia4Nota: Number(comparaDetalhes.competencia4Nota),
          competencia4Justificativa: String(
            comparaDetalhes.competencia4Justificativa
          ),
          alteradoNota4: competencia4Alterada,

          competencia5Nota: Number(comparaDetalhes.competencia5Nota),
          competencia5Justificativa: String(
            comparaDetalhes.competencia5Justificativa
          ),
          alteradoNota5: competencia5Alterada,

          justificativaFinal: String(comparaDetalhes.justificativaFinal),
          alteradoJustificativa: devolutivaAlterada,
          notaAvaliacaoIA: Number(rating),
          feedbackGeralIA: String(comment),
          notaFinal:
            auxnotaPlataforma +
            auxnotaProfessor,
        },
      };

      //console.log("Dados enviados: ", payload);

      // const payload = {
      //   reviewScore: Number(Number(essayDetails.reviewScore).toFixed(0)),
      //   score: Number(Number(essayDetails.score).toFixed(0)),
      //   reviewText: devolutive,
      //   status: "Finished",
      //   ReviewDetails: Id,
      //   redacaoDetalhadaProfessor: {
      //     idRedacao: String(paramsData.idRedacao),
      //     token: String(props.match.params.token),
      //     idEstudante: String(paramsData.idEstudante),
      //     idProfessor: String(paramsData.professorId),
      //     idCorrecao: paramsData.idCorrecao
      //       ? String(paramsData.idCorrecao)
      //       : null,
      //     competencia1Nota: Number(auxnotaPlataforma),
      //     alteradoNota1: false,
      //     competencia1Justificativa: "teste",

      //     competencia2Nota: Number(comparaDetalhes.competencia2Nota),
      //     alteradoNota2: competencia2Alterada,
      //     competencia2Justificativa:
      //       comparaDetalhes.competencia2Justificativa || null,

      //     competencia3Nota: Number(comparaDetalhes.competencia3Nota),
      //     alteradoNota3: competencia3Alterada,
      //     competencia3Justificativa:
      //       comparaDetalhes.competencia3Justificativa || null,

      //     competencia4Nota: Number(comparaDetalhes.competencia4Nota),
      //     alteradoNota4: competencia4Alterada,
      //     competencia4Justificativa:
      //       comparaDetalhes.competencia4Justificativa || null,

      //     competencia5Nota: Number(comparaDetalhes.competencia5Nota),
      //     alteradoNota5: competencia5Alterada,
      //     competencia5Justificativa:
      //       comparaDetalhes.competencia5Justificativa || null,

      //     justificativaFinal: comparaDetalhes.justificativaFinal || null,
      //     alteradoJustificativa: devolutivaAlterada,
      //     notaAvaliacaoIA: Number(rating),
      //     feedbackGeralIA: String(comment),
      //     notaFinal:
      //       Number(Number(essayDetails.reviewScore).toFixed(0)) +
      //       Number(Number(essayDetails.score).toFixed(0)),
      //   },
      // };

      //console.log("Dados enviados: ", payload);

      apiSecure
        .put("/Essay/teacher/" + props.match.params.token + "/review", payload)
        .then((response) => {
          setJson(response.data);
          toggleOpen(true);
          setFinish(false);
          setRemake(false);
          setReadyToSend(false); // Reset após o envio
          history.push(
            "/teacher/essay/correction/" + props.match.params.token,
            {
              state: { studentId: props.location.studentId },
            }
          );
        })
        .catch((err) => {
          console.error(err);
          setRun(false);
          setFinish(false);
          setRemake(false);
          setReadyToSend(false); // Reset após erro
        });
    }
  }, [
    competencia2Alterada,
    competencia3Alterada,
    competencia4Alterada,
    competencia5Alterada,
    readyToSend,
  ]);

  return (
    <>
      <GetEssayDetails
        token={props.match.params.token}
        callback={(e) => setEssayDetails(e)}
      />
      <GetReviewByToken
        token={props.match.params.token}
        callback={(e) => setReviewDetails(e)}
      />
      {essayDetails && (
        <>
          <GetThemeDetails
            id={essayDetails.themeId}
            callback={(e) => setTheme(e)}
          />
        </>
      )}

      {theme && theme.id > 0 && (
        <GetMotivatingText
          id={theme.id}
          callback={(e) => setMotivatingTexts(e)}
        />
      )}

      {theme && theme.themeTypeId && (
        <>
          <GetGenre callback={(e) => handleGenre(e)} />
          <GetType callback={(e) => handleType(e)} />
        </>
      )}
      {open == false && (
        <div
          style={{
            position: "fixed",
            margin: "auto",
            left: "65%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={loadingLogo}
            alt=""
            style={{
              objectFit: "cover",
              objectPosition: "center",
              position: "relative",
              borderRadius: "500rem",
              width: "15%",
              backgroundColor: "var(--background)",
            }}
          />
        </div>
      )}
      <Dialog open={open} aria-labelledby="form-dialog-title" fullScreen>
        {/* POPUP PROPOSTA */}
        <div id="dvPopUpProposal" style={{ display: "none" }}>
          <div
            className="popUpBg"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              width: "100%",
              height: "100vh",
              zIndex: "5000",
              position: "fixed",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => closePopUpProposal()}
          ></div>
          <div
            className={classes.motivatingTextsPopUp}
            style={{
              backgroundColor: "var(--white)",
              position: "fixed",
              zIndex: "5001",
              width: "96vw",
            }}
          >
            <div style={{ flexWrap: "wrap", display: "flex" }}>
              <Typography
                variant={"h6"}
                style={{
                  textTransform: "uppercase",
                  color: "var(--blue-light)",
                  width: "95%",
                }}
              >
                Tema:
              </Typography>
              <h3
                style={{
                  textTransform: "uppercase",
                  color: "var(--blue-light)",
                  width: "5%",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={() => closePopUpProposal()}
              >
                X
              </h3>
            </div>
            <Typography variant="subtitle1" component="subtitle1">
              {theme.title}
            </Typography>
            <Typography
              variant={"h6"}
              style={{
                marginTop: 15,
                textTransform: "uppercase",
                color: "var(--blue-light)",
              }}
            >
              Redação para:
            </Typography>
            <Typography variant="subtitle1" component="subtitle1">
              {type}
            </Typography>
            <Typography
              variant={"h6"}
              style={{
                marginTop: 15,
                textTransform: "uppercase",
                color: "var(--blue-light)",
              }}
            >
              Gênero textual:
            </Typography>
            <Typography variant="subtitle1" component="subtitle1">
              {genre}
            </Typography>
            <Typography
              variant={"h6"}
              style={{
                marginTop: 15,
                textTransform: "uppercase",
                color: "var(--blue-light)",
              }}
            >
              Proposta:
            </Typography>
            <div
              className="ql-editor"
              variant="subtitle1"
              component="subtitle1"
              style={{
                textAlign: "justify",
                height: "100%",
                minHeight: "25rem",
              }}
              dangerouslySetInnerHTML={{
                __html: essayDetails?.details ?? theme.description,
              }}
            />
            <hr
              style={{
                marginTop: 30,
                marginBottom: 30,
                height: 1,
                width: "100%",
              }}
            />

            {motivatingTexts.length > 0 && (
              <>
                <Typography
                  variant={"h6"}
                  style={{
                    marginTop: 15,
                    textTransform: "uppercase",
                    color: "var(--blue-light)",
                  }}
                >
                  Textos de Apoio
                </Typography>
                <div style={{ marginTop: 15, padding: "1.5%" }}>
                  {motivatingTexts.map((e) => (
                    <>
                      {e.isFile && (
                        <img
                          alt={e.id}
                          style={{
                            display: "block",
                            margin: "auto",
                            width: "100%",
                          }}
                          src={e.text}
                        />
                      )}
                      {!e.isFile && (
                        <div
                          style={{ textAlign: "justify", textIndent: 50 }}
                          dangerouslySetInnerHTML={{
                            __html: e.text,
                          }}
                        ></div>
                      )}
                      <Divider style={{ margin: 30 }} />
                    </>
                  ))}
                </div>
              </>
            )}
            <Divider />
          </div>
        </div>
        <Topbar menu="home" profile="10" style={{ position: "fixed" }} />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <div
          style={{
            textTransform: "uppercase",
            color: "var(--blue-light)",
            backgroundColor: "var(--white)",
            padding: "1%",
          }}
        >
          <center>
            <h4> Correção da redação de </h4>
            <h2> {essayDetails?.studentName ?? ""} </h2>
            <h4> {essayDetails?.classroomDescription ?? ""} </h4>
          </center>
        </div>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: 10,
              }}
            >
              <Button
                className={classes.buttons}
                variant="contained"
                onClick={() =>
                  history.push({
                    pathname:
                      "/teacher/class/" +
                      essayDetails.classroomId +
                      "/student/" +
                      essayDetails.essayToken,
                    state: {
                      classroomId: essayDetails.classroomId,
                      essayId: essayDetails.id,
                    },
                  })
                }
                style={{
                  background: "var(--blue-light)",
                  color: "var(--white)",
                  padding: "1%",
                }}
              >
                Retornar para a listagem de estudantes
              </Button>
            </div>
            <ErrorList json={json} />
            <div className={classes.wordCount}>
              {essayDetails && essayDetails.minAmountWords > 0 && (
                <Typography
                  variant={"subtitle2"}
                  className={classes.title}
                  style={{ float: "left" }}
                >
                  Mínimo de {essayDetails.minAmountWords} e máximo de{" "}
                  {essayDetails.maxAmountWords} palavras.
                </Typography>
              )}
              <Typography
                variant={"subtitle2"}
                className={classes.title}
                style={{ float: "right" }}
              >
                <span style={{ color: "darkred" }}>{wordCount}</span> palavras
                encontradas
              </Typography>
            </div>

            <div className={classes.container}>
              {/* REDACAO */}
              <div id="dvEssay" className={classes.dvEssayContent}>
                <DialogContentText>
                  <div className={classes.dvEssayTitle}>
                    <center>
                      <h3
                        style={{
                          textTransform: "uppercase",
                          color: "var(--white)",
                        }}
                      >
                        Texto do estudante
                      </h3>
                    </center>
                  </div>
                </DialogContentText>
                <div className={classes.inputArea}>
                  <Correction json={json} text={title + `\n` + text} />
                </div>
              </div>

              {/* TEMA */}
              <div id="dvTheme" className={classes.dvThemeContent}>
                <DialogContentText>
                  <div className={classes.dvThemeTitle}>
                    <center>
                      <h3
                        style={{
                          textTransform: "uppercase",
                          color: "var(--white)",
                        }}
                      >
                        PROPOSTA
                      </h3>
                    </center>
                  </div>
                </DialogContentText>
                <div className={classes.motivatingTexts}>
                  <Typography
                    variant={"h6"}
                    style={{
                      textTransform: "uppercase",
                      color: "var(--blue-light)",
                    }}
                  >
                    Tema:
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    {theme.title}
                  </Typography>
                  <Typography
                    variant={"h6"}
                    style={{
                      marginTop: 15,
                      textTransform: "uppercase",
                      color: "var(--blue-light)",
                    }}
                  >
                    Redação para:
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    {type}
                  </Typography>
                  <Typography
                    variant={"h6"}
                    style={{
                      marginTop: 15,
                      textTransform: "uppercase",
                      color: "var(--blue-light)",
                    }}
                  >
                    Gênero textual:
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    {genre}
                  </Typography>
                  <Typography
                    variant={"h6"}
                    style={{
                      marginTop: 15,
                      textTransform: "uppercase",
                      color: "var(--blue-light)",
                    }}
                  >
                    Proposta:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="subtitle1"
                    className="ql-editor"
                    style={{ textAlign: "justify", minHeight: "25rem" }}
                    dangerouslySetInnerHTML={{
                      __html: essayDetails?.details ?? theme.description,
                    }}
                  ></Typography>
                  <hr
                    style={{
                      marginTop: 30,
                      marginBottom: 30,
                      height: 1,
                      width: "100%",
                    }}
                  />

                  {motivatingTexts.length > 0 && (
                    <>
                      <Typography
                        variant={"h6"}
                        style={{
                          marginTop: 15,
                          textTransform: "uppercase",
                          color: "var(--blue-light)",
                        }}
                      >
                        Textos de Apoio
                      </Typography>
                      <div style={{ marginTop: 15, padding: "1.5%" }}>
                        {motivatingTexts.map((e) => (
                          <>
                            {e.isFile && (
                              <img
                                alt={e.id}
                                style={{
                                  display: "block",
                                  margin: "auto",
                                  width: "100%",
                                }}
                                src={e.text}
                              />
                            )}
                            {!e.isFile && (
                              <div
                                style={{ textAlign: "justify", textIndent: 50 }}
                                dangerouslySetInnerHTML={{
                                  __html: e.text,
                                }}
                              ></div>
                            )}
                            <Divider style={{ margin: 30 }} />
                          </>
                        ))}
                      </div>
                    </>
                  )}
                  <Divider />
                </div>
              </div>

              {/* AVALIACAO */}
              <div id="dvTextEvaluation" className={classes.dvTextEvaluation}>
                {openDescriptors && descriptores && essayDetails && (
                  <>
                    <DialogContentText>
                      <div className={classes.dvTextEvaluationTitle}>
                        <center>
                          <h3
                            style={{
                              textTransform: "uppercase",
                              color: "var(--white)",
                            }}
                          >
                            Avaliar redação
                          </h3>
                        </center>
                      </div>
                    </DialogContentText>
                    <div className={classes.dvTextEvaluationContent}>
                      <BotoesInvalidacao
                        plagio={plagio}
                        validacao={validacao}
                        correcaoIA={correcaoIA}
                      />
                      {descriptores[0]?.typeId === 2 && (
                        <>
                          <div style={{ display: "none" }}>
                            {(wMax1 = wMax2 = wMax3 = "100%")}
                            {(direction = "column")}
                          </div>
                          {!hasNotaProf && (
                            <div>
                              <div style={{ marginBottom: "2%" }}>
                                <center>
                                  <h4
                                    style={{
                                      textTransform: "uppercase",
                                      color: "var(--blue-light)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Atribua uma nota selecionando um dos
                                    critérios para cada tipo de competência
                                    abaixo
                                  </h4>
                                </center>
                              </div>
                              <hr></hr>
                            </div>
                          )}
                        </>
                      )}
                      {isMobile && (
                        <div style={{ display: "none" }}>
                          {(direction = "column")}
                          {(wMax1 = wMax2 = wMax3 = "100%")}
                        </div>
                      )}
                      {/* NOTA DA PLATAFORMA */}
                      <div className={classes.descriptorBox}>
                        <div className={classes.descriptorTitle}>
                          <strong
                            style={{
                              color: "var(--blue-light)",
                              textTransform: "uppercase",
                              fontSize: "14pt",
                            }}
                          >
                            Nota da Plataforma
                          </strong>{" "}
                          <p
                            style={{
                              color: "var(--blue-dark)",
                              textAlign: "justify",
                            }}
                          >
                            Nota atribuída ao aluno pela correção automática da
                            plataforma{" "}
                          </p>
                        </div>

                        <div className={classes.descriptorScore}>
                          <p
                            style={{
                              color: "var(--blue-light)",
                              marginBottom: "1%",
                            }}
                          >
                            0 a 200
                          </p>
                        </div>
                        <div className={classes.descriptorOption}>
                          <p>
                            <TextField
                              variant={"outlined"}
                              type={"number"}
                              label="Nota"
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  max: 200,
                                  "aria-label": "weight",
                                },
                              }}
                              className={classes.descriptorGrade}
                              value={
                                essayDetails.score > 200
                                  ? Number(essayDetails.score).toFixed(0)
                                  : Number(essayDetails.score).toFixed(0)
                              }
                              onChange={(e) =>
                                setEssayDetails({
                                  ...essayDetails,
                                  score: Number(
                                    e.target.value > 200 ? 200 : e.target.value
                                  ),
                                })
                              }
                            ></TextField>
                          </p>
                        </div>
                      </div>

                      {/* NOTA DO PROFESSOR */}
                      {set.map((e) => {
                        let notaCorrespondente = null;
                        let justificativa = "";
                        if (hasNotaProf) {
                          switch (e.id) {
                            case 11:
                              justificativa = "";
                              notaCorrespondente = Number(
                                descriptoresProf.competencia2Nota
                              );
                              //console.log(notaCorrespondente);
                              break;
                            case 12:
                              justificativa = "";
                              notaCorrespondente = Number(
                                descriptoresProf.competencia3Nota
                              );
                              //console.log(notaCorrespondente);
                              break;
                            case 13:
                              justificativa = "";
                              notaCorrespondente = Number(
                                descriptoresProf.competencia4Nota
                              );
                              //console.log(notaCorrespondente);
                              break;
                            case 14:
                              justificativa = "";
                              notaCorrespondente = Number(
                                descriptoresProf.competencia5Nota
                              );
                              //console.log(notaCorrespondente);
                              break;
                            default:
                              justificativa = "Sem justificativa disponível.";
                          }
                        } else if (detalhes) {
                          switch (e.id) {
                            case 11:
                              justificativa =
                                detalhes?.competencia2Justificativa ||
                                justificativa;
                              notaCorrespondente = Number(
                                detalhes.competencia2Nota
                              );
                              break;
                            case 12:
                              justificativa =
                                detalhes?.competencia3Justificativa ||
                                justificativa;
                              notaCorrespondente = Number(
                                detalhes.competencia3Nota
                              );
                              break;
                            case 13:
                              justificativa =
                                detalhes?.competencia4Justificativa ||
                                justificativa;
                              notaCorrespondente = Number(
                                detalhes.competencia4Nota
                              );
                              break;
                            case 14:
                              justificativa =
                                detalhes?.competencia5Justificativa ||
                                justificativa;
                              notaCorrespondente = Number(
                                detalhes.competencia5Nota
                              );
                              break;
                            default:
                              justificativa = "Sem justificativa disponível.";
                          }
                        }

                        return (
                          <>
                            <div
                              key={e.descriptorId}
                              className={classes.descriptorBox}
                              style={{ flexDirection: direction }}
                            >
                              <div
                                className={classes.descriptorTitle}
                                style={{ width: wMax1 }}
                              >
                                <strong
                                  style={{
                                    color: "var(--blue-light)",
                                    textTransform: "uppercase",
                                    fontSize: "14pt",
                                  }}
                                >
                                  {e.category}
                                </strong>{" "}
                                <p
                                  style={{
                                    color: "var(--blue-dark)",
                                    textAlign: "justify",
                                  }}
                                >
                                  {e.description}
                                </p>
                              </div>
                              <div
                                className={classes.descriptorScore}
                                style={{ width: wMax2 }}
                              >
                                <p
                                  style={{
                                    color: "var(--blue-light)",
                                    marginTop: "1%",
                                    marginBottom: "1%",
                                  }}
                                >
                                  0 a {e.maxScore}
                                </p>
                              </div>
                              <div
                                className={classes.descriptorOption}
                                style={{ width: wMax3 }}
                              >
                                {e.typeId === 1 && (
                                  <TextField
                                    variant="outlined"
                                    label="Nota"
                                    type={"number"}
                                    id={e.id}
                                    className={classes.descriptorGrade}
                                    onChange={(value) =>
                                      handleChangeType1(value, e)
                                    }
                                    defaultValue={e.score}
                                    inputProps={{
                                      min: "0",
                                      max: e.maxScore,
                                      step: "1",
                                    }}
                                    pattern="[0-9]*"
                                  ></TextField>
                                )}
                                {e.typeId === 2 && (
                                  <Descriptor2
                                    e={e}
                                    notaCorrespondente={notaCorrespondente}
                                    handleChange={(id, a, b) =>
                                      handleChangeType2(id, a, b)
                                    }
                                  />
                                )}
                              </div>
                              {/* Adicionando a justificativa no accordion */}
                              {correcaoIA && !hasNotaProf && (
                                <Accordion>
                                  <AccordionItem
                                    title="DADOS DA CORREÇÃO GEMINI"
                                    className={classes.Accordion}
                                  >
                                    <p>
                                      <b>Nota sugerida pelo Gemini: </b>
                                      {notaCorrespondente}
                                    </p>
                                    <p>
                                      <b>Linha de Raciocínio do Gemini: </b>
                                      {justificativa}
                                    </p>
                                  </AccordionItem>
                                </Accordion>
                              )}
                            </div>
                          </>
                        );
                      })}

                      <TextField
                        label="Devolutiva"
                        helperText="* Esta resposta não é obrigatória"
                        multiline
                        rows={10}
                        variant="outlined"
                        value={devolutive}
                        onChange={(e) => setDevolutive(e.target.value)}
                        onKeyDown={changeDevolutiva}
                        className={classes.devolutive}
                      ></TextField>
                      <div className={classes.reviewScoreBox}>
                        <Typography>
                          <strong
                            style={{
                              textTransform: "uppercase",
                              color: "var(--blue-light)",
                            }}
                          >
                            Nota do professor:&nbsp;
                            {hasNotaProf
                              ? descriptoresProf.notaProf
                              : auxnotaProfessor > 0
                              ? auxnotaProfessor
                              : "0"}
                            &nbsp;/&nbsp;800
                          </strong>
                        </Typography>

                        <Typography
                          style={{
                            textTransform: "uppercase",
                            color: "var(--blue-light)",
                          }}
                        >
                          Nota da plataforma:&nbsp;
                          {hasNotaProf
                            ? descriptoresProf.notaLT
                            : auxnotaPlataforma > 0
                            ? auxnotaPlataforma
                            : "0"}
                          &nbsp;/&nbsp;200
                        </Typography>
                        <br></br>
                        <div style={{ display: "none" }}>
                          {hasNotaProf
                            ? (finalGradeBox =
                                Number(descriptoresProf.notaProf) +
                                  Number(descriptoresProf.notaLT) >=
                                600
                                  ? "var(--green)"
                                  : "var(--red)")
                            : (finalGradeBox =
                                Number(somaNotas) +
                                  Number(essayDetails.score) >=
                                600
                                  ? "var(--green)"
                                  : "var(--red)")}
                        </div>
                        <div
                          style={{
                            backgroundColor: finalGradeBox,
                            padding: "3%",
                            borderRadius: 5,
                          }}
                        >
                          <Typography
                            style={{
                              textTransform: "uppercase",
                              color: "var(--white)",
                            }}
                          >
                            <h3>Nota Final</h3>
                            <h1 style={{ color: "white" }}>
                              {hasNotaProf
                                ? Number(descriptoresProf.notaProf) +
                                  Number(descriptoresProf.notaLT)
                                : auxnotaFinal > 0
                                ? auxnotaFinal
                                : "**"}
                            </h1>
                          </Typography>
                        </div>
                      </div>
                      {/* <hr></hr>
                      <div className="flex justify-around mb-1">
                        <div className="botoesCardNota">
                          <Button
                            className={classes.buttons}
                            variant="contained"
                            onClick={toggleFeedback}
                            style={{
                              display: "inherit",
                              background: "var(--blue)",
                              color: "var(--white)",
                              padding: "1%",
                            }}
                          >
                            COMPARTILHAR FEEDBACK
                          </Button>
                        </div>
                      </div> */}
                      {openDescriptors === true && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              margin: 10,
                            }}
                          ></div>

                          {loading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              id="openTeacherNote"
              style={{ width: "98%", margin: "1%", display: "none" }}
            >
              <div
                style={{
                  width: "98%",
                  margin: "1%",
                  backgroundColor: "var(--blue-light)",
                  color: "var(--white)",
                  padding: "1%",
                  borderTopLeftRadius: "0.25rem",
                  borderTopRightRadius: "0.25rem",
                }}
              >
                <center>
                  <h3>SOLICITAR REESCRITA</h3>
                </center>
              </div>
              <TextField
                label="Devolutiva *"
                helperText="Insira uma devolutiva para liberar o botão"
                multiline
                rows={10}
                variant="outlined"
                onChange={(e) => setDevolutive(e.target.value)}
                style={{ width: "98%", margin: "1%" }}
              ></TextField>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    cancelAssignGrade();
                  }}
                  style={{ padding: "1%" }}
                >
                  Cancelar
                </Button>
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  disabled={loading || devolutive.length < 3}
                  title="Digite algo para habilitar o botão"
                  onClick={() => {
                    setRemake(true);
                  }}
                  style={{
                    backgroundColor: "var(--blue-light)",
                    color: "var(--white)",
                    borderRadius: "2px",
                    padding: "1%",
                  }}
                >
                  Solicitar reescrita
                </Button>
              </div>
            </div>

            <div id="buttonsDefault">
              <div className={classes.buttonsDefault}>
                {/* {readOnly === "false" ? ( */}
                {/* Botoes Default */}
                <Button
                  className={classes.buttons}
                  id={"btnRewrite"}
                  variant="contained"
                  onClick={() => {
                    if (paramsData.statusRedacao !== "6") {
                      requestRewrite();
                    } else {
                      requestRewrite();
                      //alert("Você já enviou uma nota para esta redação.");
                    }
                  }}
                  style={{
                    backgroundColor: "var(--white)",
                    color: "var(--blue-light)",
                    border: "2px solid var(--blue-light)",
                    padding: "1%",
                  }}
                >
                  Solicitar reescrita
                </Button>
                <Button
                  className={classes.buttons}
                  id={"btnCancelAssignGrade"}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    cancelAssignGrade();
                  }}
                  style={{
                    display: "none",
                    padding: "1%",
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  className={classes.buttons}
                  id={"btnPopUpProposal"}
                  variant="contained"
                  style={{
                    backgroundColor: "var(--white)",
                    color: "var(--blue-light)",
                    border: "2px solid var(--blue-light)",
                    display: "none",
                    padding: "1%",
                  }}
                  onClick={() => {
                    popUpProposal();
                  }}
                >
                  Visualizar Proposta
                </Button>
                <Button
                  className={classes.buttons}
                  id={"btnSendGrade"}
                  variant="contained"
                  onClick={() => {
                    if (paramsData.statusRedacao !== "6") {
                      toggleFeedback();
                    } else {
                      toggleFeedback();
                      //alert("Você já enviou uma nota para esta redação.");
                    }
                  }}
                  title="Enviar correção"
                  style={{
                    display: "none",
                    background: "var(--green)",
                    color: "var(--white)",
                    padding: "1%",
                  }}
                  disabled={notaNaoAtribuida}
                >
                  Enviar e Concluir
                </Button>
                <Button
                  className={classes.buttons}
                  id={"btnAssignGrade"}
                  variant="contained"
                  onClick={() => {
                    assignGrade();
                  }}
                  style={{
                    display: "inherit", //readOnly === "true" ? "none" : "inherit",
                    background: "var(--green)",
                    color: "var(--white)",
                    padding: "1%",
                  }}
                >
                  Atribuir nota
                </Button>

                <Button
                  className={classes.buttons}
                  variant="contained"
                  onClick={() =>
                    history.push({
                      pathname:
                        "/teacher/class/" +
                        essayDetails.classroomId +
                        "/student/" +
                        essayDetails.essayToken,
                      state: {
                        classroomId: essayDetails.classroomId,
                        essayId: essayDetails.id,
                      },
                    })
                  }
                  // disabled={readOnly === true}
                  style={{
                    display: "inherit", //readOnly === "true" ? "none" : "inherit",
                    background: "var(--blue-light)",
                    color: "var(--white)",
                    padding: "1%",
                  }}
                >
                  Retornar para a listagem de estudantes
                </Button>
              </div>
            </div>

            {essayDetails && !openTeacherNote && (
              <>
                <div id="correctionHistory">
                  <div
                    style={{
                      backgroundColor: "var(--blue-light)",
                      borderTopLeftRadius: "0.25rem",
                      BorderTopRightRadius: "0.25rem",
                      padding: "1%",
                      marginTop: "1.5rem",
                      marginBottom: "1%",
                    }}
                  >
                    <center>
                      <Typography
                        variant={"h6"}
                        style={{
                          color: "var(--white)",
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        Histórico de correções
                      </Typography>
                    </center>
                  </div>
                  <CorrectionHistory token={props.match.params.token} />
                </div>
              </>
            )}

            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle
                id="alert-dialog-slide-title"
                style={{ color: "var(--blue-light)" }}
              >
                {"DESEJA CONCLUIR A CORREÇÃO?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Ao enviar a correção não será possível realizar alterações.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDialogClose}
                  style={{ color: "var(--red)", padding: "1%" }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleDialogConfirm}
                  style={{ color: "var(--green)", padding: "1%" }}
                >
                  Concluir
                </Button>
              </DialogActions>
            </Dialog>
          </DialogContent>
        </form>
      </Dialog>
      {/* Modal para possível plágio */}
      <Modal
        open={showModalPlagio}
        onClose={() => setShowModalPlagio(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        disableEnforceFocus={false}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
          tabIndex={-1}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            POSSÍVEL PLÁGIO DETECTADO
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            [JUSTIFICATIVA/FONTES]
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setShowModalPlagio(false)}
            sx={{ mt: 3, mr: 1 }}
            style={{
              display: "inherit",
              background: "var(--white)",
              color: "var(--blue)",
              borderColor: "var(--blue)",
              padding: "1% 2%",
            }}
          >
            Fechar
          </Button>
        </Box>
      </Modal>
      {/* MODAL FEEDBACK */}
      <Dialog
        open={showModalFeedback}
        onClose={() => setShowModalFeedback(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">ENVIAR FEEDBACK</DialogTitle>
        <DialogContent>
          <Typography id="dialog-description" sx={{ mt: 2 }}>
            Qual a nota que você dá para o Feedback fornecido pelo Gemini?
          </Typography>
          <Rating
            name="feedback-rating"
            value={rating}
            precision={1}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            sx={{ mt: 1, mb: 2 }}
          />
          <TextField
            label="Comentários (opcional)"
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            onChange={(event) => setComment(event.target.value)}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            mt: 2,
          }}
        >
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={() => setShowModalFeedback(false)}
            style={{
              display: "inherit",
              background: "var(--white)",
              borderColor: "var(--blue)",
              color: "var(--blue)",
              padding: "1%",
              marginRight: "10px",
            }}
          >
            Fechar
          </Button>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            color="success"
            onClick={() => handleEnviarRedacao()}
            style={{
              display: "inherit",
              background: "var(--green)",
              color: "var(--white)",
              padding: "1%",
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  descriptorBox: {
    margin: "1% 0",
    display: "flex",
    padding: "20px auto",
    minHeight: "30px",
    borderBottom: "1px solid #e8e8e8",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
    "@media(max-width: 1280px)": {
      width: "100%",
      flexDirection: "column",
      textJustify: "center",
    },
  },
  descriptorTitle: {
    width: "70%",
    textJustify: "center",
    whiteSpace: "break-spaces",
    marginLeft: 10,
    margin: "auto",
    fontSize: "14pt",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 20,
    },
    "@media(max-width: 1280px)": {
      width: "100%",
      margin: 0,
      fontSize: "16pt",
    },
  },
  descriptorScore: {
    width: "10%",
    textAlign: "center",
    margin: "auto",
    minWidth: 45,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "@media(max-width: 600px)": {
      textJustify: "center",
      margin: "1%",
    },
    "@media (max-width:1280px)": {
      margin: "1%",
      width: "94vw",
      minHeight: "0",
      maxHeight: "none",
    },
  },
  descriptorOption: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "@media (max-width:1280px)": {
      width: "100%",
      minHeight: "0",
      maxHeight: "none",
    },
  },
  reviewScoreBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fafafa",
    border: "1px solid var(--blue-light)",
    borderRadius: 5,
    minWidth: 400,
    textAlign: "center",
    padding: 20,
    margin: "50px auto 10px auto",
    [theme.breakpoints.down("md")]: {
      minWidth: 100,
    },
    width: "100%",
    marginTop: "3%",
    "@media(max-width: 1280px)": {
      width: "95%",
    },
  },
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: "35%",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      minHeight: "35%",
      margin: "auto",
    },
  },
  root: {
    display: "flex",
    height: "50%",
    margin: "1%",
  },
  top: {
    display: "flex",
    flexDirection: "column",
    height: "60%",
    maxHeight: "600px",
    margin: theme.spacing(2),
    overflow: "scroll",
  },
  motivatingTextsPopUp: {
    [theme.breakpoints.down("md")]: {
      order: 1,
      minHeight: 300,
      width: "100%",
      margin: "20px auto",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "700px",
    maxHeight: "700px",
    border: " 1px solid #bfbfbf",
    borderRadius: "5px",
    padding: 20,
    margin: "2vw",
    overflowY: "scroll",
    alignSelf: "flex-start",
    width: "96%",
    "@media(max-width: 600px)": {
      width: "98%",
      margin: 0,
    },
    "@media (max-width:1280px)": {
      width: "98vw",
      margin: "20px",
      minHeight: "0",
      maxHeight: "98vh",
    },
  },

  motivatingTexts: {
    [theme.breakpoints.down("md")]: {
      order: 1,
      minHeight: 300,
      width: "100%",
      margin: "20px auto",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "590px",
    maxHeight: "590px",
    border: " 1px solid #bfbfbf",
    borderRadius: "5px",
    padding: 20,
    margin: "24px 20px",
    overflowY: "scroll",
    alignSelf: "flex-start",
    width: "96%",
    "@media(max-width: 600px)": {
      width: "98%",
      margin: 0,
    },
    "@media (max-width:1280px)": {
      width: "94vw",
      minHeight: "0",
      maxHeight: "98vh",
    },
  },

  inputArea: {
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #bfbfbf",
    borderRadius: "5px",
    padding: 20,
    margin: "24px 20px",
    alignSelf: "flex-start",
    width: "98%",
    overflowY: "scroll",
    minHeight: "590px",
    maxHeight: "590px",

    "@media (max-width:1280px)": {
      width: "94vw",
      margin: "20px 0",
      minHeight: "0",
      maxHeight: "none",
    },
  },

  devolutive: {
    width: "100%",
    margin: "2.5% 0px",
    "@media (max-width:1280px)": {
      width: "95%",
    },
  },

  wordCount: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      marginBottom: "3%",
    },
    width: "98%",
    margin: "auto",
    marginTop: 30,
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
    border: "1px  solid lightgray",
    borderRadius: 5,
  },
  sidebar: {
    margin: "1%",
    maxWidth: "30%",
  },
  vertical: {
    display: "flex",
    flexDirection: "column",
  },
  buttons: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "3%",
      margin: "5px",
      padding: "1px",
    },
    display: "flex",
    margin: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    margin: theme.spacing(3),
  },
  textarea: {
    margin: "1%",
    minWidth: "60%",
    minHeight: "600px",
  },
  title: {
    margin: "1%",
    minWidth: "10%",
    "@media(max-width: 1280px)": {
      fontSize: "16pt",
    },
  },
  typography: {
    margin: "1%",
    maxWidth: "100px",
    minHeight: "250px",
    justifyContent: "right",
    alignItems: "center",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    display: "flex",
    margin: "10px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  dvTextEvaluation: {
    margin: "1% 1% 0 0",
    width: "50%",
    display: "none",

    "@media(max-width: 1280px)": {
      width: "94vw",
      padding: "0%",
      fontSize: "16pt",
    },
  },
  dvTextEvaluationTitle: {
    backgroundColor: "var(--blue-light)",
    borderTopRightRadius: "0.25rem",
    padding: "1%",
    "@media(max-width: 1280px)": {
      width: "94vw",
      borderTopLeftRadius: "0.25rem",
      padding: "1%",
    },
  },
  dvTextEvaluationContent: {
    width: "95%",
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "590px",
    border: " 1px solid #bfbfbf",
    borderRadius: "5px",
    padding: 20,
    margin: "24px 20px",

    "@media(max-width: 1280px)": {
      width: "100%",
      margin: "0",
      padding: "2% 2%",
      flexDirection: "center",
      fontSize: "14pt",
    },
    "@media (max-width:1280px)": {
      width: "94vw",
      margin: "20px 0",
      minHeight: "0",
      maxHeight: "none",
    },
  },
  dvEssayTitle: {
    backgroundColor: "var(--blue-light)",
    borderTopLeftRadius: "0.25rem",
    padding: "1%",

    "@media(max-width: 1280px)": {
      borderTopRightRadius: "0.25rem",
      marginTop: "1% 0",
      width: "100%",
    },
  },
  dvEssayContent: {
    width: "50%",
    margin: "1% 0 0 1%",

    "@media(max-width: 1280px)": {
      width: "94vw",
      margin: "0",
      padding: "0",
      fontSize: "16pt",
    },
  },
  dvThemeTitle: {
    backgroundColor: "var(--blue-light)",
    borderTopRightRadius: "0.25rem",
    padding: "1%",
    "@media(max-width: 1280px)": {
      borderTopLeftRadius: "0.25rem",
      width: "94vw",
    },
  },
  dvThemeContent: {
    width: "50%",
    margin: "1% 1% 0 0",

    "@media(max-width: 1280px)": {
      width: "94vw",
      margin: "0",
      padding: "0",
      fontSize: "16pt",
    },
  },
  descriptorGrade: {
    marginTop: "auto",
    marginBottom: "auto",
    minWidth: "5%",
    "@media(max-width: 1280px)": {
      minWidth: "10vw",
      marginLeft: "2.5%",
    },
  },
  buttonsDefault: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: 10,
    "@media(max-width: 600px)": {
      display: "block",
      flexDirection: "column",
    },
  },
  "@global": {
    ".MuiDialogContent-root": {
      overflowY: "unset",
    },
  },
  Accordion: {
    padding: 10,
    textAlign: "justify",
    backgroundColor: "var(--background)",
    margin: "1% 1%",
    borderRadius: "0.25rem",
    "&:hover": {
      backgroundColor: "#f2f1f0",
    },
  },
}));

import React from "react";
import { Topbar } from "../../../component/Topbar";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import ThemesAutocomplete from "../../../component/ThemesAutocomplete";

function NewEssay() {
	const classes = useStyles();

	return (
		<>
			<div>
				<Topbar profile="11" menu="newTheme" />
			</div>
			<div className={classes.container}>
				<h2>Propor novo tema</h2>
			</div>
			<div className={classes.container}>
				<Paper elevation={1} className={classes.paper}>
					<div className={classes.right}>
						<Autocomplete
							id="combo-box-demo"
							className={classes.buttons}
							getOptionLabel={(option) => option.title}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Redação para"
									variant="outlined"
								/>
							)}
						/>

						<Autocomplete
							id="combo-box-demo"
							className={classes.buttons}
							getOptionLabel={(option) => option.title}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Gênero textual"
									variant="outlined"
								/>
							)}
						/>

						<div className={classes.content}></div>
						<ThemesAutocomplete />

						<TextField
							id="date"
							label="Data da proposta"
							type="date"
							defaultValue="2020-09-24"
							className={classes.datePickers}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							id="date"
							label="Data de finalização"
							type="date"
							defaultValue="2021-05-24"
							className={classes.datePickers}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
				</Paper>

				<Paper elevation={1} className={classes.paper}>
					<div className={classes.left}>
						<Autocomplete
							multiple
							id="combo-box-demo"
							getOptionLabel={(option) => option.title}
							className={classes.buttons}
							renderInput={(params) => (
								<TextField {...params} label="Séries" variant="outlined" />
							)}
						/>
						<TextField
							id="outlined-multiline-static"
							label="Proposta"
							multiline
							className={classes.buttons}
							rows={15}
							variant="outlined"
						/>
						<FormControl style={{ margin: "1%" }} component="fieldset">
							<FormLabel component="legend">
								Selecione os critérios da redação
							</FormLabel>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox name="gilad" />}
									label="Inserir Título"
								/>
								<FormControlLabel
									control={<Checkbox name="jason" />}
									label="Evitar gírias"
								/>
								<FormControlLabel
									control={<Checkbox name="antoine" />}
									label="Não ferir direitos humanos"
								/>
							</FormGroup>
						</FormControl>

						<div className={classes.container}>
							<Button
								variant="contained"
								color="primary"
								className={classes.buttons}
							>
								Enviar
							</Button>
						</div>
					</div>
				</Paper>
			</div>
		</>
	);
}

export default NewEssay;

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			width: "90%",
			marginLeft: "5%",
		},
		[theme.breakpoints.between("sm", "md")]: {
			flexDirection: "column",
			width: "90%",
			marginLeft: "5%",
		},
		"@media (min-width: 1280px)": {
			flexDirection: "row",
			width: "90%",
			marginLeft: "5%",
			justifyContent: "center",
		},
		marginTop: "1%",
	},
	content: {
		display: "flex",
		flexDirection: "row",
	},
	paper: {
		magin: "0.1%",
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			width: "100%",
		},
		[theme.breakpoints.between("sm", "md")]: {
			flexDirection: "column",
			width: "100%",
		},
		"@media (min-width: 1280px)": {
			flexDirection: "column",
			width: "50%",
			margin: "0.1%",
		},
	},
	centered: {
		display: "flex",
		flexDirection: "column",

		width: "50%",
		marginLeft: "5%",
		marginTop: "1%",
		justifyContent: "center",
		alignItems: "center",
	},
	right: {
		display: "flex",
		flexDirection: "column",
		margin: "1%",
		width: "90%",
		float: "center",
		justifyContent: "center",
		alignItems: "center",
	},
	left: {
		display: "flex",
		margin: "1%",

		flexDirection: "column",
		width: "90%",
		float: "center",
		justifyContent: "center",
		alignItems: "center",
	},
	buttons: {
		margin: "0.5%",
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			width: "100%",
		},
		[theme.breakpoints.between("sm", "md")]: {
			flexDirection: "column",
			width: "50%",
		},
		"@media (min-width: 1280px)": {
			flexDirection: "column",
			width: "50%",
		},
	},
	datePickers: {
		minWidth: "45%",
		marginRight: "1%",
		marginLeft: "1%",
		marginTop: "1%",
		justifyContent: "left",
		alignItems: "left",
	},
}));

import React, { useEffect } from "react";
import { apiSecure } from "domain/api";
import { useHistory } from "react-router-dom";

export function CheckToken(props) {
  console.log(props);
  let history = useHistory();

  useEffect(() => {
    apiSecure
      .get("/Essay/token/" + props.match.params.token)
      .then(function (response) {
        console.log(response.data);

        if (response.data.isTeacher === true) {
          history.push("/teacher/home");
        } else {
          history.push("/student/essay/" + response.data.token);
        }
      })
      .catch(function (error) {
        console.error(error);
        history.push("/home");
      });
  }, []);

  return <></>;
}

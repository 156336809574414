import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const ErrorList = (props) => {
	const classes = useStyles();
	const [load, setLoad] = useState();
	const [sSintaxe, setsSintaxe] = useState(0);
	const [sAcentuacao, setsAcentuacao] = useState(0);
	const [sOrtografia, setsOrtografia] = useState(0);
	const [sSemantica, setsSemantica] = useState(0);
	const [sOutras, setsOutras] = useState(0);

	var count = 0;
	var GSintaxe = 0;
	var GAcentuacao = 0;
	var GOrtografia = 0;
	var GSemantica = 0;
	var GOutras = 0;

	useEffect(() => {
		HandleJson(props.json);

		function HandleJson(json) {
			var object = JSON.parse(JSON.stringify(json.matches));
			object.map((item) => {
				count = count + 1;

				switch (item.rule.category.name) {
					case "Acentuação":
						GAcentuacao = GAcentuacao + 1;
						break;
					case "Palavras Normalmente Confusas":
						GAcentuacao = GAcentuacao + 1;
						break;
					case "Calinadas":
						GOrtografia = GOrtografia + 1;
						break;
					case "Capitalização":
						GOrtografia = GOrtografia + 1;
						break;
					case "Composição":
						GOrtografia = GOrtografia + 1;
						break;
					case "Erro de Escrita":
						GOrtografia = GOrtografia + 1;
						break;
					case "Erros Ortográficos":
						GOrtografia = GOrtografia + 1;
						break;
					case "Maiúsculo / Minúsculo":
						GOrtografia = GOrtografia + 1;
						break;
					case "Palavras Compostas":
						GOrtografia = GOrtografia + 1;
						break;
					case "Regras Específicas da Wikipédia":
						GOrtografia = GOrtografia + 1;
						break;
					case "Sem categoria Definida":
						GOrtografia = GOrtografia + 1;
						break;
					case "Style":
						GOrtografia = GOrtografia + 1;
						break;
					case "Contrações":
						GOutras = GOutras + 1;
						break;
					case "Desenvolvimento":
						GOutras = GOutras + 1;
						break;
					case "Linguagem Formal":
						GOutras = GOutras + 1;
						break;
					case "Redundância":
						GOutras = GOutras + 1;
						break;
					case "Regionalismos":
						GOutras = GOutras + 1;
						break;
					case "Tipografia":
						GOutras = GOutras + 1;
						break;
					case "Confusão de palavras":
						GSemantica = GSemantica + 1;
						break;
					case "Dicas de estilo para uma escrita criativa":
						GSemantica = GSemantica + 1;
						break;
					case "Erros de Tradução":
						GSemantica = GSemantica + 1;
						break;
					case "Escrita Criativa":
						GSemantica = GSemantica + 1;
						break;
					case "Frases - Feitas e Expressões Idiomáticas":
						GSemantica = GSemantica + 1;
						break;
					case "Regras de Marcas e Termos Registrados":
						GSemantica = GSemantica + 1;
						break;
					case "Semântica":
						GSemantica = GSemantica + 1;
						break;
					case "Análise de texto":
						GSintaxe = GSintaxe + 1;
						break;
					case "Dicas de redação":
						GSintaxe = GSintaxe + 1;
						break;
					case "Estilo":
						GSintaxe = GSintaxe + 1;
						break;
					case "Gramática Geral":
						GSintaxe = GSintaxe + 1;
						break;
					case "Pontuação":
						GSintaxe = GSintaxe + 1;
						break;
					case "Repetições":
						GSintaxe = GSintaxe + 1;
						break;
					case "Repetições(estilo)":
						GSintaxe = GSintaxe + 1;
						break;
					case "Sintaxe":
						GSintaxe = GSintaxe + 1;
						break;
				}
			});
		}
		setsAcentuacao(GAcentuacao);
		setsOrtografia(GOrtografia);
		setsSemantica(GSemantica);
		setsSintaxe(GSintaxe);
		setsOutras(GOutras);
		setLoad(true);
	}, []);

	if (load) {
		return (
			<div>
				<Typography style={{ textAlign: "center", textTransform:"uppercase", color:"var(--blue-light)" }} variant={"h6"}>
					Erros encontrados
				</Typography>

				<div className={classes.container} style={{ justifyContent: "center" }}>
					{/* <div
						className={classes.box}
						style={{
							backgroundColor: "#ffe0ad",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<Typography>Acentuação {sAcentuacao} </Typography>
					</div>
					<div
						className={classes.box}
						style={{
							backgroundColor: "#cef2d5",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<Typography>Ortografia {sOrtografia}</Typography>
					</div> */}
					<div
						className={classes.box}
						style={{
							backgroundColor: "#cef2d5",
							textAlign: "center",
							alignItems: "center",
							borderRadius: "0.25rem",
							marginBottom:'2%',
						}}
					>
						<Typography>ORTOGRAFIA: {sOrtografia + sAcentuacao}</Typography>
					</div>
					<div
						className={classes.box}
						style={{
							backgroundColor: "#e1b6fc",
							textAlign: "center",
							alignItems: "center",
							borderRadius: "0.25rem",
							marginBottom:'2%',
						}}
					>
						<Typography>SEMÂNTICA: {sSemantica}</Typography>
					</div>
					<div
						className={classes.box}
						style={{
							backgroundColor: "#ed9332",
							textAlign: "center",
							alignItems: "center",
							borderRadius: "0.25rem",
							marginBottom:'2%',
						}}
					>
						<Typography>SINTAXE: {sSintaxe}</Typography>
					</div>
					<div
						className={classes.box}
						style={{
							backgroundColor: "#b4bbfa",
							textAlign: "center",
							alignItems: "center",
							borderRadius: "0.25rem",
							marginBottom:'2%',
						}}
					>
						<Typography>OUTROS: {sOutras}</Typography>
					</div>
				</div>
			</div>
		);
	} else {
		return <div>Carregando...</div>;
	}
};
export default ErrorList;

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			width: "90%",
			marginLeft: "5%",
		},
		[theme.breakpoints.between("sm", "md")]: {
			flexDirection: "column",
			width: "90%",
			marginLeft: "5%",
		},
		"@media (min-width: 1280px)": {
			flexDirection: "row",
			width: "100%",
		},
		marginTop: "1%",
	},
	box: {
		height: "50px",
		justifyContent: "center",
		borderRadius: 10,
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			width: "100%",
		},
		[theme.breakpoints.between("sm", "md")]: {
			flexDirection: "column",
			width: "100%",
			margin: "1%",
		},
		"@media (min-width: 1280px)": {
			flexDirection: "row",
			width: "200px",
			margin: 5,
		},
	},
}));

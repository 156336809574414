import styled from 'styled-components'

export const Container = styled.div`
margin: 0% 1% 1% 1%;
padding: 1rem;
background: var(--white);
border-bottom-left-radius: 0.25rem;
border-bottom-right-radius: 0.25rem;
display: flex;
max-width: 98%;    
flex-wrap: wrap;
color: var(--blue-dark);

h3{
    display: block;
    width: 100%;

}

`
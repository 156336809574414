import React, { useEffect } from "react";
import { apiSecure } from "domain/api";

export function GetPending(props) {
  useEffect(() => {
    apiSecure
      .get("/Essay/Student/l/pending")
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {});
  }, []);
  return <></>;
}

export function GetRecent(props) {
  useEffect(() => {
    apiSecure
      .get("/Essay/Student/l/finishedrecently")
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {});
  }, []);
  return <></>;
}

export function GetPendingWithTeacher(props) {
  useEffect(() => {
    apiSecure
      .get("/Essay/student/l/pendingwithteacher")
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {});
  }, []);
  return <></>;
}

export function GetFinished(props) {
  useEffect(() => {
    apiSecure
      .get("/Essay/student/l/finished")
      .then(function (response) {
        props.callback(response.data);
      })
      .catch(function (error) {});
  }, []);
  return <></>;
}

export function GetEssayDetails(props) {
  useEffect(() => {
    let isLoading = true;
    if (isLoading) {
      apiSecure
        .get("/Essay/student/token/" + props.token)
        .then(function (response) {
          if (response.data) props.callback(response.data);
        })
        .catch(function (error) {
          if (props.id > 0) {
            ById();
          }
        });

      return () => (isLoading = false);
    }

    function ById() {
      apiSecure
        .get("/Essay/student/" + props.id)
        .then(function (response) {
          props.callback(response.data);
        })
        .catch(function (error) {});
      return <></>;
    }
  }, []);
  return <></>;
}
